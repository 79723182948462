import { takeEvery, put, call, select } from "redux-saga/effects";

import {
  getSecurityIncidents, 
  getSecurityUsers,
  getCommTypes,
  getComms,
  getCommsId,
  getEmails,
  getImports,
  getStagingEmails,
  getSpecificEmails,
  createSecurityIncident,
  updateSecurityIncident,
  deleteSecurityIncident,
  createComm,
  updateComm,
  deleteComm,
  createEmails,
  updateEmails,
  deleteEmails,
  createSecurityUsers,
  updateSecurityUsers,
  deleteSecurityUsers,
  createImports,
  updateImports,
  deleteImports,
  createStagingEmails,
  updateStagingEmails,
  promoteStagingEmails,
  deleteStagingEmails,
  sendEmails,
 } from './securityIncident.api';
import { types as securityIncidentTypes, actions as securityIncidentActions } from './securityIncident.index';
import { actions as uiActions }     from '../ui/ui.index';
import { actions as errorActions }  from '../error/error.index';
import { actions as toastActions }  from '../toast/toast.index';


export function* fetchSecurityIncidents() {
  try {
    // call endpoint
    yield put(securityIncidentActions.setSecurityIncidentsLoading(true));
    let response =  yield call(getSecurityIncidents);
    let payload  =  response.data.payload;

    // update state
    yield put(securityIncidentActions.setSecurityIncidents(payload));
    yield put(securityIncidentActions.setAllSecIds());
    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
    const message      =  `Unable to get security incidents: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}



export function* fetchSecurityUsers() {
  try {
    // call endpoint
    yield put(securityIncidentActions.setSecurityUsersLoading(true));
    let response =  yield call(getSecurityUsers);
    console.log('get security users response', response)
    let payload  =  response.data.payload;

    // update state
    yield put(securityIncidentActions.setSecurityUsers(payload));
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
    const message      =  `Unable to get security users: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchCommTypes() {
  try {
    // call endpoint
    yield put(securityIncidentActions.setCommTypesLoading(true));
    let response =  yield call(getCommTypes);
    let payload  =  response.data.payload;

    // update state
    yield put(securityIncidentActions.setCommTypes(payload));
    yield put(securityIncidentActions.setCommTypesLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setCommTypesLoading(false));
    const message      =  `Unable to get comm types: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchComms(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setCommsLoading(true));
    let response = {}
    if (action.payload){
      response =  yield call(getCommsId, action.payload);
    }
    else{
      response =  yield call(getComms);
    }
    let payload  =  response.data.payload;

    // update state
    yield put(securityIncidentActions.setComms(payload));
    yield put(securityIncidentActions.setAllCommIds());
    yield put(securityIncidentActions.setCommsLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setCommsLoading(false));
    const message      =  `Unable to get comms: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchEmails(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setEmailsLoading(true));
    let response =  yield call(getEmails, action.payload);
    let payload  =  response.data.payload;

    // update state
    yield put(securityIncidentActions.setEmails(payload));
    yield put(securityIncidentActions.setEmailsLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setEmailsLoading(true));
    const message      =  `Unable to get emails: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchSpecificEmails(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setSpecificEmailsLoading(true));
    let response =  yield call(getSpecificEmails, action.payload);
    let payload  =  response.data.payload;

    // update state
    yield put(securityIncidentActions.setEmails(payload));
    yield put(securityIncidentActions.setSpecificEmailsLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setSpecificEmailsLoading(true));
    const message      =  `Unable to get emails: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchImports(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setImportsLoading(true));
    let response =  yield call(getImports, action.payload);
    let payload  =  response.data.payload;

    // update state
    yield put(securityIncidentActions.setImports(payload));
    yield put(securityIncidentActions.setImportsLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setImportsLoading(true));
    const message      =  `Unable to get imports: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchStagingEmails(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setStagingEmailsLoading(true));
    let response =  yield call(getStagingEmails, action.payload);
    let payload  =  response.data.payload;
    // update state
    yield put(securityIncidentActions.setStagingEmails(payload));
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setStagingEmailsLoading(true));
    const message      =  `Unable to get staging emails: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitCreateSecurityIncident(action) {
  try{
    // extract relevant variables
    yield put(securityIncidentActions.setSecurityIncidentsLoading(true));
    const payload =  action.payload;
    // submit it to changeEntity api endpoint
    const response = yield call(createSecurityIncident, payload);

    yield put(securityIncidentActions.requestSecurityIncidents());

    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
    yield put(toastActions.createToast('Security Incident Created Successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue creating the Security Incident. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
      yield put(securityIncidentActions.requestSecurityIncidents());
      yield put(toastActions.createToast('Security Incident Not Created!', {type: 'error'}))
    }
    else{
      yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }
}


export function* submitCreateComm(action) {
  try{
    // extract relevant variables
    yield put(securityIncidentActions.setCommsLoading(true));
    const payload =  action.payload;
    // submit it to changeEntity api endpoint
    const response = yield call(createComm, payload);
    yield put(securityIncidentActions.requestComms(payload.sec_id));
    yield put(securityIncidentActions.setCommsLoading(false));
    yield put(toastActions.createToast('Comm Created Successfully!', {type: 'success'}))
  }                  
  catch (error) {
    const message      =  `There was an issue creating the Comm. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(securityIncidentActions.setCommsLoading(false));
      yield put(toastActions.createToast('Comm Not Created!', {type: 'error'}))
    }
    else{
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }
}


export function* submitCreateEmails(action) {
  try{
    // extract relevant variables
    yield put(securityIncidentActions.setEmailsLoading(true));
    const payload =  action.payload;
    // submit it to changeEntity api endpoint
    const response = yield call(createEmails, payload);
    yield put(securityIncidentActions.requestEmails(payload.sec_comm_id));
    yield put(securityIncidentActions.setEmailsLoading(false));
    yield put(toastActions.createToast('emails Created Successfully!', {type: 'success'}))
  }                  
  catch (error) {
    const message      =  `There was an issue creating the Comm. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(securityIncidentActions.setEmailsLoading(false));
      yield put(toastActions.createToast('emails Not Created!', {type: 'error'}))
    }
    else{
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }
}

export function* submitCreateSecurityUsers(action) {
  try{
    // extract relevant variables
    yield put(securityIncidentActions.setSecurityUsersLoading(true));
    const payload =  action.payload;
    // submit it to changeEntity api endpoint
    const response = yield call(createSecurityUsers, payload);
    payload['id'] = response.data.user_id;
    payload['created_date'] = new Date().toISOString();
    yield put(securityIncidentActions.updateAddSecurityUser(payload));
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
    yield put(toastActions.createToast('Security User Created Successfully!', {type: 'success'}))
  }                  
  catch (error) {
    const message      =  `There was an issue creating the Security User. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(securityIncidentActions.setSecurityUsersLoading(false));
      yield put(toastActions.createToast('Security User Not Created!', {type: 'error'}))
    }
    else{
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }

}

export function* submitCreateImports(action) {
  try{
    // extract relevant variables
    yield put(securityIncidentActions.setImportsLoading(true));
    const payload =  action.payload;
    // submit it to changeEntity api endpoint
    const response = yield call(createImports, payload);
    console.log('response', response)
    payload['pkey'] = response.data.import_id;
    payload['created_date'] = new Date().toISOString();
    yield put(securityIncidentActions.updateAddImport(payload));
    yield put(securityIncidentActions.setImportsLoading(false));
    yield put(toastActions.createToast('Imports Created Successfully!', {type: 'success'}))
  }                  
  catch (error) {
    const message      =  `There was an issue creating the imports. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(securityIncidentActions.setImportsLoading(false));
      yield put(toastActions.createToast('Imports Not Created!', {type: 'error'}))
    }
    else{
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }
}

export function* submitCreateStagingEmails(action) {
  try{
    // extract relevant variables
    yield put(securityIncidentActions.setStagingEmailsLoading(true));
    const payload =  action.payload;
    // submit it to changeEntity api endpoint
    const response = yield call(createStagingEmails, payload);
    // payload['id'] = response.data.user_id;
    // payload['created_date'] = new Date().toISOString();
    //yield put(securityIncidentActions.updateAddSecurityUser(payload));
    yield put(securityIncidentActions.setSTagingEmails(payload));
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    yield put(toastActions.createToast('Staging Emails Created Successfully!', {type: 'success'}))
  }                  
  catch (error) {
    const message      =  `There was an issue creating the staging emails. Please try again. ${error}`;
    if (message.includes('504')){
      yield put(securityIncidentActions.setStagingEmailsLoading(false));
      yield put(toastActions.createToast('Staging Emails Not Created!', {type: 'error'}))
    }
    else{
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }
}


export function* submitUpdateSecurityIncident(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setSecurityIncidentsLoading(true));
    const payload =  action.payload;
    yield call(updateSecurityIncident, payload)
    yield put (securityIncidentActions.updateOneSecurityIncident(payload));
    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
    yield put(toastActions.createToast('Security Incident Updated Successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
    const message      =  `Unable to update security incident state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitUpdateComm(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setCommsLoading(true));
    const payload =  action.payload;
    yield call(updateComm, payload);
    yield put (securityIncidentActions.updateOneComm(payload));
    yield put(securityIncidentActions.setCommsLoading(false));
    yield put(toastActions.createToast('Comm Updated Successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setCommsLoading(false));
    const message      =  `Unable to update comm state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitUpdateEmails(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setEmailsLoading(true));
    const payload =  action.payload;
    yield call(updateEmails, payload);
    yield put (securityIncidentActions.updateOneCommContent(payload));
    yield put(securityIncidentActions.setEmailsLoading(false));
    yield put(toastActions.createToast('emails Updated Successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setEmailsLoading(false));
    const message      =  `Unable to update emails state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitUpdateImports(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setImportsLoading(true));
    const payload =  action.payload;
    yield call(updateImports, payload);
    //yield put (securityIncidentActions.updateOneCommContent(payload));
    yield put(securityIncidentActions.setImportsLoading(false));
    yield put(toastActions.createToast('imports Updated Successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setImportsLoading(false));
    const message      =  `Unable to update imports state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitUpdateStagingEmails(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setStagingEmailsLoading(true));
    const payload =  action.payload;
    yield call(updateStagingEmails, payload);
    yield put (securityIncidentActions.updateOneStagingEmail(payload));
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    yield put(toastActions.createToast('staging emails Updated Successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    const message      =  `Unable to update staging emails state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitPromoteStagingEmails(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setStagingEmailsLoading(true));
    const payload =  action.ids;
    let response = yield call(promoteStagingEmails, payload);
    yield put (securityIncidentActions.updatePromoteStagingEmail(payload));
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    yield put(toastActions.createToast('staging emails promoted Successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    const message      =  `Unable to promote staging emails state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitUpdateSecurityUsers(action) {
  try {
    // call endpoint
    yield put(securityIncidentActions.setSecurityUsersLoading(true));
    const payload =  action.payload;
    yield call(updateSecurityUsers, payload);
    yield put(securityIncidentActions.updateOneSecurityUser(payload));
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
    yield put(toastActions.createToast('Security Users Updated Successfully!', {type: 'success'}))
  }
  catch(error) {
    // handle ui effects
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
    const message      =  `Unable to update security users state: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitDeleteSecurityIncident(action) {
  try{
    yield put(securityIncidentActions.setSecurityIncidentsLoading(true));
    const sec_id =  action.sec_id;
    const response = yield call(deleteSecurityIncident, sec_id);
    yield put(securityIncidentActions.updateDeleteSecurityIncident(sec_id));
    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
    yield put(toastActions.createToast('Security Incident deleted Successfully!', {type: 'success'}))
  }
  catch (error) {
    yield put(securityIncidentActions.setSecurityIncidentsLoading(false));
    const message      =  `There was an issue deleting the message. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteComm(action) {
  try{
    yield put(securityIncidentActions.setCommsLoading(true));
    const sec_comm_id =  action.sec_comm_id;
    const response = yield call(deleteComm, sec_comm_id);
    yield put(securityIncidentActions.updateDeleteComm(sec_comm_id));
    yield put(securityIncidentActions.setCommsLoading(false));
    yield put(toastActions.createToast('Comm deleted Successfully!', {type: 'success'}))
  }
  catch (error) {
    yield put(securityIncidentActions.setCommsLoading(false));
    const message      =  `There was an issue deleting the Comm. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteEmails(action) {
  try{
    yield put(securityIncidentActions.setEmailsLoading(true));
    const comm_content_id =  action.comm_content_id;
    const response = yield call(deleteEmails, comm_content_id);
    yield put(securityIncidentActions.updateDeleteCommContent(comm_content_id));
    yield put(securityIncidentActions.setEmailsLoading(false));
    yield put(toastActions.createToast('emails deleted Successfully!', {type: 'success'}))
  }
  catch (error) {
    yield put(securityIncidentActions.setEmailsLoading(false));
    const message      =  `There was an issue deleting the emails. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteImports(action) {
  try{
    yield put(securityIncidentActions.setImportsLoading(true));
    const import_id =  action.imports_id;
    const response = yield call(deleteImports, import_id);
    yield put(securityIncidentActions.updateDeleteImport(import_id));
    yield put(securityIncidentActions.setImportsLoading(false));
    yield put(toastActions.createToast('import deleted Successfully!', {type: 'success'}))
  }
  catch (error) {
    yield put(securityIncidentActions.setImportsLoading(false));
    const message      =  `There was an issue deleting the import. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteStagingEmails(action) {
  try{
    yield put(securityIncidentActions.setStagingEmailsLoading(true));
    const staging_email_id =  action.staging_email_id;
    const response = yield call(deleteStagingEmails, staging_email_id);
    yield put(securityIncidentActions.updateDeleteStagingEmail(staging_email_id));
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    yield put(toastActions.createToast('staging email deleted Successfully!', {type: 'success'}))
  }
  catch (error) {
    yield put(securityIncidentActions.setStagingEmailsLoading(false));
    const message      =  `There was an issue deleting the staging emails. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteSecurityUsers(action) {
  try{
    yield put(securityIncidentActions.setSecurityUsersLoading(true));
    const user_id =  action.user_id;
    const response = yield call(deleteSecurityUsers, user_id);
    yield put(securityIncidentActions.updateDeleteSecurityUser(user_id));
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
    yield put(toastActions.createToast('Security User deleted Successfully!', {type: 'success'}))
  }
  catch (error) {
    yield put(securityIncidentActions.setSecurityUsersLoading(false));
    const message      =  `There was an issue deleting the Security User. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitSendEmails(action) {
  try{
    // extract relevant variables
    //yield put(securityIncidentActions.setImportsLoading(true));
    const payload =  action.payload;
    // submit it to changeEntity api endpoint
    const response = yield call(sendEmails, payload);
    //yield put(securityIncidentActions.setImportsLoading(false));
    yield put(toastActions.createToast('Email Sent Successfully!', {type: 'success'}))
  }                  
  catch (error) {
    const message      =  `There was an issue sending the email. Please try again. ${error}`;
    if (message.includes('504')){
      //yield put(securityIncidentActions.setImportsLoading(false));
      yield put(toastActions.createToast('Email Not sent Created!', {type: 'error'}))
    }
    else{
      const toastOptions =  {type: 'error', autoClose: false};
      yield put(toastActions.createToast(message, toastOptions));
    }
  }
}

// watcher saga
export default function securityIncidentSagas() {
  return [
    takeEvery(securityIncidentTypes.REQUEST_SECURITY_INCIDENTS, fetchSecurityIncidents),
    takeEvery(securityIncidentTypes.REQUEST_SECURITY_USERS, fetchSecurityUsers),
    takeEvery(securityIncidentTypes.REQUEST_COMM_TYPES, fetchCommTypes),
    takeEvery(securityIncidentTypes.REQUEST_COMMS, fetchComms),
    takeEvery(securityIncidentTypes.REQUEST_EMAILS, fetchEmails),
    takeEvery(securityIncidentTypes.REQUEST_IMPORTS, fetchImports),
    takeEvery(securityIncidentTypes.REQUEST_STAGING_EMAILS, fetchStagingEmails),
    takeEvery(securityIncidentTypes.REQUEST_CREATE_SECURITY_INCIDENT, submitCreateSecurityIncident),
    takeEvery(securityIncidentTypes.REQUEST_EDIT_SECURITY_INCIDENT, submitUpdateSecurityIncident),
    takeEvery(securityIncidentTypes.REQUEST_DELETE_SECURITY_INCIDENT, submitDeleteSecurityIncident),
    takeEvery(securityIncidentTypes.REQUEST_CREATE_COMM, submitCreateComm),
    takeEvery(securityIncidentTypes.REQUEST_EDIT_COMM, submitUpdateComm),
    takeEvery(securityIncidentTypes.REQUEST_DELETE_COMM, submitDeleteComm),
    takeEvery(securityIncidentTypes.REQUEST_CREATE_EMAILS, submitCreateEmails),
    takeEvery(securityIncidentTypes.REQUEST_EDIT_EMAILS, submitUpdateEmails),
    takeEvery(securityIncidentTypes.REQUEST_DELETE_EMAILS, submitDeleteEmails),
    takeEvery(securityIncidentTypes.REQUEST_SPECIFIC_EMAILS, fetchSpecificEmails),
    takeEvery(securityIncidentTypes.REQUEST_CREATE_SECURITY_USERS, submitCreateSecurityUsers),
    takeEvery(securityIncidentTypes.REQUEST_EDIT_SECURITY_USERS, submitUpdateSecurityUsers),
    takeEvery(securityIncidentTypes.REQUEST_DELETE_SECURITY_USERS, submitDeleteSecurityUsers),
    takeEvery(securityIncidentTypes.REQUEST_CREATE_IMPORTS, submitCreateImports),
    takeEvery(securityIncidentTypes.REQUEST_EDIT_IMPORTS, submitUpdateImports),
    takeEvery(securityIncidentTypes.REQUEST_DELETE_IMPORTS, submitDeleteImports),
    takeEvery(securityIncidentTypes.REQUEST_CREATE_STAGING_EMAILS, submitCreateStagingEmails),
    takeEvery(securityIncidentTypes.REQUEST_EDIT_STAGING_EMAILS, submitUpdateStagingEmails),
    takeEvery(securityIncidentTypes.REQUEST_PROMOTE_STAGING_EMAILS, submitPromoteStagingEmails),
    takeEvery(securityIncidentTypes.REQUEST_DELETE_STAGING_EMAILS, submitDeleteStagingEmails),
    takeEvery(securityIncidentTypes.REQUEST_SEND_EMAILS, submitSendEmails),

  ];
}
