import DOMPurify from 'dompurify';
import React from 'react';

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
}

export const getCommType = (comm_id, comm_types) => {
  const commType = comm_types.find(commType => commType.id === comm_id);
  return commType ? commType.display_name : '';
}

export const getCommTypeId = (type, comm_types) => {
  const commTypeId = comm_types.find(commType => commType.internal_name === type);
  return commTypeId ? commTypeId.id : 1;
}


export const parseHtmlFromDb = (emails, justString="") => {
  // emails is the redux object, justString would be from db
  let htmlItems = [];
  if (justString){
    htmlItems = justString.match(/<[^>]+>[^<]*/g); 

  }
  else{
    htmlItems = emails[0].body.match(/<[^>]+>[^<]*/g); 
  }
  if (!htmlItems){
    return [];
  }
  const emailContents = [];
  const sanitizer = DOMPurify.sanitize;
  for (let i = 0; i < htmlItems.length; i++){
    if (htmlItems[i].includes('<table')){
      let tableHtml = '<table class="entity-table">';
      i+= 1;
      while (htmlItems[i] !== '</table>'){
        tableHtml += htmlItems[i];
        i++;
      }
      emailContents.push(<div key={"stringentity-table-" + i} className="Container" dangerouslySetInnerHTML={{__html: sanitizer(tableHtml)}}></div>)
    }
    else{
      let line = htmlItems[i] + htmlItems[i + 1];
      i++;
      emailContents.push(<div key={"fromString-" + i} className="Container" dangerouslySetInnerHTML={{__html: sanitizer(line)}}></div>)
    }
  };
  return emailContents;
}

export const htmlToString = (html) => {
  let stringHtml = '';
  html.forEach(element => {
    let key = element.key.split('-')[0];
    if (key === 'stringentity'){
      let htmlLine = element.props.dangerouslySetInnerHTML.__html;
      stringHtml += htmlLine;
    }
    else if (key === 'entity'){
      let tableHtml = [];
      tableHtml.push('<table width="468" cellspacing="0" cellpadding="0" border="1" align="center" class="entity-table">');
      tableHtml.push('<thead>');
      tableHtml.push('<tr>');
      let columns = element.props.children[0].props.children.props.children;
      let width = 100 / columns.length;
      columns.forEach((header, index) => {
        tableHtml.push(`<th width="${width}%">${header.props.children}</th>`);
      });
      tableHtml.push('</tr>');
      tableHtml.push('</thead>');
      tableHtml.push('<tbody>');
      columns = element.props.children[1].props.children;
      columns.forEach((row, i) => {
        tableHtml.push('<tr>');
        row.props.children.forEach((data, j) => {
          tableHtml.push(`<td width="${width}%">${data.props.children}</td>`);
        });
        tableHtml.push('</tr>');
      });
      tableHtml.push('</tbody>');
      tableHtml.push('</table>');
      tableHtml.forEach(line => {
        stringHtml += line;
      });
      tableHtml = [];
    }
    else{
      let text = element.props.children;
      let htmlLine = `<p class="${key}">${text}</p>`;
      if (!text){
        htmlLine = element.props.dangerouslySetInnerHTML.__html;
      }
      stringHtml += htmlLine;
    }
  });
  return stringHtml
}