import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as uiActions,
} from '../../../ducks/ui/ui.index';
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import { Modal, Button, Form, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import {Formik} from 'formik';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
/* ========= PACKAGE IMPORTS ========= */

const schema = yup.object().shape({
  status: yup.string().required(),
  title: yup.string().required(),
  description: yup.string().required(),
  start_date: yup.string().required(),
});

const mapStateToProps = () => ({
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading                :  uiActions.setPageLoading,
    requestCreateSecurityIncident :  securityIncidentActions.requestCreateSecurityIncident,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const CreateSecurityIncidentModal = ({requestCreateSecurityIncident, setPageLoading}) => {
  const { t } = useTranslation(["translation"]);
  const [startDate, setStartDate] = useState(new Date());
  const [startDateUtc, setStartDateUtc] = useState(startDate.toUTCString());
  const [endDate, setEndDate] = useState(new Date());
  const [endDateUtc, setEndDateUtc] = useState(startDate.toUTCString());
  const [status, setStatus] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  let styles = {
    buttonRight   : {
      width       : '8rem',
      margin      : '1rem'
    },
    buttonLeft: {
      width       : '8rem',
      margin      : '1rem auto 1rem 1rem',
      float       : 'left'
    }
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const [showConfirmation,setShowConfirmation] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const resetValues = () => {
    setTitle('');
    setDescription('');
    setEndDate(new Date());
    setStartDate(new Date());
    setStatus('');

  }



  return (
    <div style={{'marginLeft': 'auto'}}>
      {/* Overlay Tooltip for button */}
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Creates a new Security Incident</Tooltip>
        }
      >
        <Button className="data-cy-create-security-incident-modal-button" variant="primary" onClick={openModal}>Create Security Incident</Button>
      </OverlayTrigger>
      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-create-security-incident-modal">
      <Formik   
          enableReinitialize
          validationSchema={schema}
          onSubmit={(values, {resetForm}) => {
            requestCreateSecurityIncident(values);
            setShowConfirmation(false);
            setPageLoading(false);
            closeModal();
            resetForm();
            resetValues();
          }}
          initialValues={{
            start_date: startDateUtc,
            start_date_utc: startDateUtc,
            end_date: endDateUtc,
            end_date_utc: endDateUtc,
            title: title,
            description: description,
            status: status,
          }}
        >
        {({
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => 
        (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create Security Incident</Modal.Title>
            </Modal.Header>
            {/* Confirmation Step Title */}
           
            <Modal.Body>
              {/* Main Step Body */}
              <div>
                <Form.Group>
                  <div style={{width: '40%', display: 'flex'}}>
                    <div style={{marginRight: '3vw'}}>
                      <Form.Label>Start Date</Form.Label>
                      <DatePicker
                          onChange            = {(date) => {
                            setStartDate(date); 
                            setStartDateUtc(date.toUTCString());
                            if (date > endDate){
                              setEndDate(date); 
                              setEndDateUtc(date.toUTCString());
                            }
                          }}
                          selected            = {startDate}
                          showTimeInput       = "true"
                          dateFormat          = "Pp"
                          style               = {{width: '100%'}}  
                          required
                      />
                    </div>
                    
                    {/* <div>
                      <Form.Label>End Date</Form.Label>
                      <DatePicker
                          onChange            = {(date) => {setEndDate(date); setEndDateUtc(date.toUTCString());}}
                          selected            = {endDate}
                          showTimeInput       = "true"
                          dateFormat          = "Pp"
                          style               = {{width: '100%'}}  
                          minDate             = {startDate}
                          required
                          
                      />
                    </div> */}
                    
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="textarea"
                    as="textarea"
                    className="data-cy-create-security-incident-modal-notification-body"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={handleBlur}
                    name="title"></Form.Control>
                    {errors.title && touched.title ? (
                      <div style={{color: 'red'}}>{errors.title}</div>
                    ) : null}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="textarea"
                    as="textarea"
                    className="data-cy-create-security-incident-modal-title"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onBlur={handleBlur}
                    name="description"></Form.Control>
                    {errors.description && touched.description ? (
                      <div style={{color: 'red'}}>{errors.description}</div>
                    ) : null}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    type="textarea"
                    as="textarea"
                    className="data-cy-create-security-incident-modal-title"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    onBlur={handleBlur}
                    name="status"></Form.Control>
                    {errors.status && touched.status ? (
                      <div style={{color: 'red'}}>{errors.status}</div>
                    ) : null}
                </Form.Group>
              </div>
            </Modal.Body>
             
            
            <Modal.Footer>
              <div style={{display: 'contents'}}>
                <Button variant="secondary" onClick={closeModal} className="data-cy-create-security-incident-modal-close" style={styles.buttonLeft}>Cancel</Button>
                <Button style={styles.buttonRight} className="data-cy-create-security-incident-modal-ok" type="submit">Submit</Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
        </Formik>

      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateSecurityIncidentModal);