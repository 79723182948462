import React, {useEffect, useState}      from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdExpandMore, MdOutlineRemoveRedEye } from "react-icons/md";
import Loading            from '../../../components/loading';
import DOMPurify from 'dompurify';
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import { Modal, Button, Tooltip, OverlayTrigger, Card } from 'react-bootstrap';
import {parseHtmlFromDb} from '../SecurityIncidentHelpers';
/* ========= PACKAGE IMPORTS ========= */

const mapStateToProps = ({securityIncidentState}) => ({
  emailContents         : securityIncidentState.emailContents,
  emails                : securityIncidentState.emails,
  specificEmailsLoading : securityIncidentState.specificEmailsLoading,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setEmailContents                : securityIncidentActions.setEmailContents,
    requestSpecificEmails           : securityIncidentActions.requestSpecificEmails,
    setEmailPreviewLoading          : securityIncidentActions.setEmailPreviewLoading,
    requestSendEmails               : securityIncidentActions.requestSendEmails
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const PreviewSendEmailModal = ({id, emails, specificEmailsLoading, requestSpecificEmails, requestSendEmails, setEmailPreviewLoading, emailContents, setEmailContents, isButton=false, previewOnly=true, body="", subject=""}) => {
  const [modalIsOpen,setIsOpen] = useState(false);
  const isInt = (value) => {
    return !isNaN(value) && 
           parseInt(Number(value)) == value && 
           !isNaN(parseInt(value, 10));
  }
  useEffect(() => {
    if (id && isInt(id) && modalIsOpen){
      setEmailContents([]);
      requestSpecificEmails(id);
    }
    if (body){
      setEmailContents(parseHtmlFromDb(null, body));
      setEmailPreviewLoading(false);
    }
  }, [modalIsOpen]);
  
  useEffect(() => {
    if (body){
      let stuff = parseHtmlFromDb(null, body);
      setEmailContents(stuff); 
    }
  }, [body]);

  useEffect(() => {
    if (emails.length === 1 && emails[0].body !== "" && emailContents.length === 0 && modalIsOpen){
      setEmailContents(parseHtmlFromDb(emails)); 
    }
  }, [emails]);

  const openModal = () => {
    setIsOpen(true);

  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const submitSend = () => {
    if (!previewOnly){
      requestSendEmails({'email_ids': [emails[0].id]});
      //send email
    }
    closeModal();
  }

  return (
    <div style={{display: 'unset'}}>
      {isButton && <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Preview email with option to send </Tooltip>
        }
      >
        <Button style={{marginRight: '1rem'}} onClick={openModal}>Preview / Send</Button>
      </OverlayTrigger>}
      {!isButton && !previewOnly && <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Preview email with option to send </Tooltip>
        }
      >
        <MdExpandMore style={{fontSize: '35px', color: '#007bff'}} onClick={openModal}/>
      </OverlayTrigger>}
      {!isButton && previewOnly && <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Preview email</Tooltip>
        }
      >
        <MdOutlineRemoveRedEye style={{fontSize: '35px', color: '#007bff'}} onClick={openModal}/>
      </OverlayTrigger>}
     
      
      <Modal size='lg' show={modalIsOpen} onHide={closeModal} className="data-cy-preview-email-modal">
      {specificEmailsLoading 
      ? <Loading height="10vh" /> 
      : <div style={{textAlign: 'center'}}>
          {!previewOnly && <Modal.Header closeButton>
            <Modal.Title>Send Email</Modal.Title>
          </Modal.Header>}
          <Card>
            <Card.Body>
              
            {!previewOnly && <div>Confirm: Are you sure you want to send this?</div>}
              <br/>
              {!subject && <h3>{emails[0].subject}</h3>}
              {subject && <h3>{subject}</h3>}
              <br/>
              {emailContents.map((element, index) => {
                return (
                  <span style={{display: 'flex', justifyContent: 'center'}} key={index} className="Container">
                    {element}
                  </span>
                )})
              }
            </Card.Body>
          </Card>
          <div style={{display: 'flex'}}>
            <Button variant="secondary" onClick={closeModal} className="data-cy-preview-email-modal-close" style={{margin: '1vw', width: '8rem', marginRight: 'auto'}}>Cancel</Button>
            {!previewOnly && <Button variant="primary" onClick={() => submitSend()} style={{float: 'right', width: '8rem', margin: '1vw'}}>Send</Button>}
          </div>
        </div>}
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(PreviewSendEmailModal);