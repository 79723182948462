import React, { useEffect, useState, useCallback } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import { Modal, Button, Form, Tooltip, OverlayTrigger} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { validateEmail, debounce } from '../SecurityIncidentHelpers';
/* ========= PACKAGE IMPORTS ========= */

const schema = yup.object().shape({
  company_id: yup.string().required(),
  email: yup.string().required(),
});

const mapStateToProps = () => ({
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    requestCreateSecurityUsers :  securityIncidentActions.requestCreateSecurityUsers,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const CreateSecurityUserModal = ({requestCreateSecurityUsers}) => {
  const { t } = useTranslation(["translation"]);
  const [companyId, setCompanyId] = useState('');
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);


  let styles = {
    buttonRight   : {
      width       : '8rem',
      margin      : '1rem'
    },
    buttonLeft: {
      width       : '8rem',
      margin      : '1rem auto 1rem 1rem',
      float       : 'left'
    }
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const [showConfirmation,setShowConfirmation] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const resetValues = () => {
    setCompanyId('');
    setEmail('');
  }

  const debouncedEmailChecker = useCallback(
		debounce(nextValue => setValidEmail(validateEmail(nextValue)), 500),
		[],
	);

  useEffect(() => {
    if (email) {
      debouncedEmailChecker(email);
    }
  },[email]);

  return (
    <div style={{'marginLeft': 'auto'}}>
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Creates a new Security User</Tooltip>
        }
      >
        <Button  className="data-cy-create-security-user-modal-button" variant="primary" onClick={openModal}>Create Security User</Button>
      </OverlayTrigger>
      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-create-security-user-modal">
      <Formik   
          enableReinitialize
          validationSchema={schema}
          onSubmit={(values, {resetForm}) => {
            if (!validateEmail(values.email)) {
              return;
            }
            requestCreateSecurityUsers(values);
            setShowConfirmation(false);
            closeModal();
            resetForm();
            resetValues();
          }}
          initialValues={{
            company_id: companyId,
            email: email,
            active: 'Y'
          }}
        >
        {({
          handleSubmit,
        }) => 
        (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create Comm</Modal.Title>
            </Modal.Header>
           
            <Modal.Body>
              <div>
                <Form.Group>
                  <div>
                    <div style={{'marginRight': 'auto', 'height': 'unset'}}>
                      <Form.Label>Company Id</Form.Label>     
                      <Form.Control style={{height: 'unset'}} type="number" as="input" value={companyId} onChange={e => setCompanyId(e.target.value)}> 
                      </Form.Control>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <div>
                    <div style={{'marginRight': 'auto', 'height': 'unset'}}>
                      <Form.Label>Email</Form.Label>     
                      <Form.Control style={{height: 'unset'}} as="input" type="email" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)}> 
                      </Form.Control>
                      {!validEmail && <div style={{color: 'red'}}>Invalid Email</div>}
                    </div>
                  </div>
                </Form.Group>
              </div>
            </Modal.Body>
             
            
            <Modal.Footer>
              <div style={{display: 'contents'}}>
                <Button variant="secondary" onClick={closeModal} className="data-cy-create-security-user-modal-close" style={styles.buttonLeft}>Cancel</Button>
                <Button style={styles.buttonRight} className="data-cy-create-security-user-modal-ok" type="submit">Submit</Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
        </Formik>

      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateSecurityUserModal);