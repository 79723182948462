import React, {useEffect, useState}      from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Button } from 'react-bootstrap';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import Loading            from '../../components/loading';
import DOMPurify from 'dompurify';
import './ComposeEmailTool.css';
import UpdateComposeEmailModal from './Modals/UpdateComposeEmailModal';
import DeleteComposeEmailModal from './Modals/DeleteComposeEmailModal';
import CreateComposeEmailModal from './Modals/CreateComposeEmailModal';
import PreviewSendEmailModal from './Modals/PreviewSendEmailModal';
import {htmlToString, parseHtmlFromDb} from './SecurityIncidentHelpers';
const mapStateToProps = ({securityIncidentState}) => {
  return {
    emailsLoading : securityIncidentState.emailsLoading,
    specificEmailsLoading : securityIncidentState.specificEmailsLoading,
    emails        : securityIncidentState.emails,
    emailContents       : securityIncidentState.emailContents,
  }
}
const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    requestSpecificEmails              : securityIncidentActions.requestSpecificEmails,
    requestEditEmails                  : securityIncidentActions.requestEditEmails,
    requestCreateEmails                : securityIncidentActions.requestCreateEmails,
    setEmailContents                   : securityIncidentActions.setEmailContents,
    setEmailPreviewLoading             : securityIncidentActions.setEmailPreviewLoading,
    setEmailsLoading                   : securityIncidentActions.setEmailsLoading,
    setSpecificEmailsLoading           : securityIncidentActions.setSpecificEmailsLoading,
    setEmails                          : securityIncidentActions.setEmails,
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};

const requiredAction = 'view_compose_email';

const ComposeEmailTool = ({id, commId, company_id, requestSpecificEmails, requestEditEmails, requestCreateEmails, setSpecificEmailsLoading, setEmailContents, setEmails, emailContents, specificEmailsLoading, composeEmailId, emails, emailPreviewLoading, setEmailPreviewLoading}) => {
  const [errorMsg, setErrorMsg] = useState('');
  const isInt = (value) => {
    return !isNaN(value) && 
           parseInt(Number(value)) == value && 
           !isNaN(parseInt(value, 10));
  }
  if (id && !isInt(id)){
    id = null;
  }
  if (commId && !isInt(commId)){
    commId = null;
  }
  if (company_id && !isInt(company_id)){
    company_id = null;
  }
  useEffect(() => {
    if (id && isInt(id)){
      requestSpecificEmails(id);
      setEmails([]);
      emails = [];
      setEmailContents([]);
      emailContents = [];
    }
    else if (!company_id){
      setEmails([]);
      emails = [];
      setEmailContents([]);
      emailContents = [];
      setEmails([{body: '', company_id: company_id ?? '', subject: '', sec_comm_id: parseInt(commId)}]);
      setSpecificEmailsLoading(false);
    }
    else{
      // validate that commId is a number, should be done for company_id and id as well.
      setEmails([{body: '', company_id: company_id ?? '', subject: '', sec_comm_id: parseInt(commId)}]);
      setSpecificEmailsLoading(false);
    }
  }, []);
  const [companyId, setCompanyId] = useState('');
  const [subject, setSubject] = useState('');

  useEffect(() => {
    if (emails.length === 1 && emails[0].body !== "" && emailContents.length === 0){
      setEmailContents(parseHtmlFromDb(emails));
    }
  }, [emails]);

  const saveEmail = () => {
    setErrorMsg('');
    let stringHtml = htmlToString(emailContents);
    if (stringHtml === ""){
      setErrorMsg("Email body cannot be empty");
      return;
    }
    if (emails[0].subject === ""){
      setErrorMsg("Subject cannot be empty");
      return;
    }
    emails[0].body = stringHtml;
    emails[0].upload_source = 'manual';
    if (!company_id){
      requestCreateEmails(emails[0]);
    }
    else{
      requestEditEmails(emails[0]);
    }
  }
  
  const onChangeCompanyId = (value) => {
    setCompanyId(value);
    emails[0]['company_id'] = value;
  }

  const onChangeSubject = (value) => {
    setSubject(value);
    emails[0]['subject'] = value;
  }


return (
  <AuthorizationWrapper requiredAction={requiredAction}>
    <br/>
    {emailPreviewLoading || specificEmailsLoading 
    ? <Loading height="10vh" /> 
    : <div style={{textAlign: 'center', margin: '1rem auto', width: '70vw', display: 'grid'}}>
      { company_id && <div style={{borderBottom: '1px solid black', paddingBottom: '5px'}}>Company ID: {company_id}</div>}
      { !company_id && <div style={{borderBottom: '1px solid black', paddingBottom: '5px'}}>Company ID: <input type="text" value={company_id ? company_id : companyId} onChange={e => onChangeCompanyId(e.target.value)}/></div>}
      <br/>
      <div>Subject: <input style={{width: '50vw'}} type="text" value={emails[0].subject} onChange={e => onChangeSubject(e.target.value)}/></div>
      <br/>
      {emailContents.map((element, index) => {
        return (
          <span style={{display: 'ruby'}} key={index} className="Container">
            {element}
            <span style={{marginLeft: '5px', verticalAlign: 'top'}} className="header">
              <UpdateComposeEmailModal index={index}/>
              <DeleteComposeEmailModal index={index}/>
            </span>
          </span>
        )})
      }
    </div>}
    <div style={{color: 'red', textAlign: 'center', marginBottom: '5px'}}>{errorMsg}</div>
    <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
      <CreateComposeEmailModal style={{marginRight: '1rem'}} type="table" />
      <CreateComposeEmailModal style={{marginRight: '1rem'}} type="paragraph" />
      <CreateComposeEmailModal type="header" />
    </div>
    <br/>
    <div style={{textAlign: 'center'}}>
      {emails.length == 1 && <PreviewSendEmailModal id={emails[0]['id']} isButton={true} previewOnly={false}/>}
      <Button style={{marginRight: '1rem'}} onClick={e => saveEmail()}>Save</Button>
      <Button onClick={() => window.history.back()}>Back</Button>
    </div>
  </AuthorizationWrapper>
)
}

export default connect(mapStateToProps, mapDispatchToProps)(ComposeEmailTool);
