import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as uiActions,
} from '../../../ducks/ui/ui.index';
import {
  actions as maintenanceEventActions
} from '../../../ducks/maintenanceEvent/maintenanceEvent.index';
import { Modal, Button, Form, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import {Formik, Field} from 'formik';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import {
  turnObjectArrayToArray
} from '../../../utils/formatFunctions'
/* ========= PACKAGE IMPORTS ========= */
const curDate = new Date();

const schema = yup.object().shape({
  is_test: yup.string().required().oneOf(['Y', 'N']),
  is_internal: yup.string().required().oneOf(['Y', 'N']),
  start_date: yup.date().required(),
  end_date: yup.date().required(),
  title: yup.string().required(),
  notification_body: yup.string().required(),
  data_centers: yup.array().required(),
  created_by: yup.string().required(),
  id: yup.number().required(),
  notification_id: yup.number().required(),
  flag: yup.string().optional(),
  jira_id: yup.string().optional(),
});

const mapStateToProps = ({uiState, userState, maintenanceEventState}) => ({
  pageLoading         :  uiState.pageLoading,
  email               :  userState.user.email, 
  all_data_centers    :  maintenanceEventState.all_data_centers
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading                :  uiActions.setPageLoading,
    submitUpdateMaintenanceEvent :  maintenanceEventActions.submitUpdateMaintenanceEvent,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};



const UpdateMaintenanceEventModal = ({submitUpdateMaintenanceEvent, setPageLoading, email, maintenance_event_details, all_data_centers}) => {
  const { t } = useTranslation(["translation"]);
  const [startDate, setStartDate] = useState(new Date(maintenance_event_details.start_date));
  const [endDate, setEndDate] = useState(new Date(maintenance_event_details.end_date));
  const [isTest, setIsTest] = useState(maintenance_event_details.is_test);
  const [isInternal, setIsInternal] = useState(maintenance_event_details.is_internal);
  const [flag, setFlag] = useState(maintenance_event_details.flag);
  const [jiraId, setJiraId] = useState(maintenance_event_details.jira_id);
  const [title, setTitle] = useState(maintenance_event_details.title);
  const [notificationBody, setNotificationBody] = useState(maintenance_event_details.notification_body);
  let dcs = []
  if (maintenance_event_details && maintenance_event_details.data_centers){
    maintenance_event_details.data_centers.forEach((dc) => {
      dcs.push(dc.system_name);
    })
  }
  const [dataCenterField, setDataCenterField] = useState(dcs);
  const data_center_dc_names = turnObjectArrayToArray(all_data_centers, 'dc_name');
  let styles = {
    error         :  {
      color       :  'red',
    },
    buttonError   :  {
      color       :  'red',
      marginLeft  :  '5px',
    },
    button        : {
      width       :  '8rem',
    },
    buttonRight   : {
      width       : '8rem',
      margin      : '1rem'
    },
    buttonLeft: {
      width       : '8rem',
      margin      : '1rem auto 1rem 1rem',
      float       : 'left'
    },
    buttonRightDisabled: {
      width       : '8rem',
      marginLeft  : '1rem',
      cursor      : 'default'
    },
    title: {
      textAlign: 'center',
      color: 'red',
    },
    marginRight: {
      marginRight: '1rem',
    },
    confirmationText: {
      marginLeft: '.5rem',
      marginBottom: '.5rem',
      marginTop: '.5rem',
    },
    confirmationHeader: {
      marginBottom: '.5rem',
      marginTop: '.5rem',
      minWidth: '120px'
    },
    infoImg: {
      maxHeight : '1.9rem',
    },
    inlineMiddleVAlign: {
      display : 'inline',
      verticalAlign : 'middle'
    },
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const [showConfirmation,setShowConfirmation] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const submitForm = (fields) => {
    submitUpdateMaintenanceEvent(fields);
    setShowConfirmation(false);
    setPageLoading(false);
    closeModal();
  }

  const handleChangeRadio = (e, values, field, setValues, typeValue) => {
    const inputValue = e.target.value;
    if (typeValue == 'is_internal'){
      setIsInternal(inputValue);
    }
    else{
      setIsTest(inputValue)
    }
    field.onChange(e);
  }

  return (
    <div>
      {/* Overlay Tooltip for button */}
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Edits this Maintenance Event</Tooltip>
        }
      >
        <Button className="data-cy-edit-message-modal-button" variant="primary" onClick={openModal} style={{width: '8rem', margin: '0 1rem'}}>Edit</Button>
      </OverlayTrigger>
      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-edit-message-modal">
      <Formik   
          enableReinitialize
          validationSchema={schema}
          onSubmit={values => submitForm(values)}
          initialValues={{
            is_test: 'N',
            is_internal: isInternal,
            start_date: startDate,
            end_date: endDate,
            title: title,
            notification_body: notificationBody,
            data_centers: dataCenterField,
            created_by: email,
            id: maintenance_event_details.id,
            notification_id: maintenance_event_details.notification_id,
            flag: flag,
            jira_id: jiraId ?? ''
          }}
        >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldTouched,
          values,
          errors,
          touched,
          setValues,
        }) => 
        (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Maintenance Event</Modal.Title>
            </Modal.Header>
            {/* Confirmation Step Title */}
           
            <Modal.Body>
              {/* Main Step Body */}
              <div>
                <Form.Group>
                  <div style={{width: '40%', display: 'flex'}}>
                  <div style={{marginRight: '3vw'}}>
                    <Form.Label>Start Date</Form.Label>
                    <DatePicker
                        onChange            = {(date) => {
                          setStartDate(date); 
                          if (date > endDate){
                            setEndDate(date); 
                          }
                        }}
                        selected            = {values.start_date}
                        showTimeInput       = "true"
                        dateFormat          = "Pp"
                        style               = {{width: '100%'}}  
                        required
                    />
                   </div>
                   
                  <div>
                    <Form.Label>End Date</Form.Label>
                    <DatePicker
                        onChange            = {(date) => {setEndDate(date)}}
                        selected            = {values.end_date}
                        showTimeInput       = "true"
                        dateFormat          = "Pp"
                        style               = {{width: '100%'}}  
                        minDate             = {values.start_date}
                        required
                        
                    />
                  </div>
                    
                  </div>
                 
                </Form.Group>
                <Form.Group>
                  <Form.Label>Audience</Form.Label>     
                  <Field>
                    {({ field }) => (
                      <Form.Group as={Row} className="data-cy-create-message-modal-internal" name="internal" value={isInternal} onBlur={handleBlur} onChange={e => handleChangeRadio(e, values, field, setValues, 'is_internal')}>
                          <Form.Check
                          type="radio"
                          name="internal"
                          label="Internal"
                          value="Y"
                          className="data-cy-create-message-modal-internal-Y"
                          id="internalYes"
                          style={styles.marginRight}
                          onChange={handleChange}
                          checked={values.is_internal === 'Y'}
                        />
                        <Form.Check
                          type="radio"
                          label="Public"
                          name="internal"
                          value="N"
                          className="data-cy-create-message-modal-internal-N"
                          id="internalNo"
                          onChange={handleChange}
                          checked={values.is_internal === 'N'}
                        />
                      </Form.Group>
                    
                    )}
                  </Field>
                  {errors.broadcast && touched.broadcast ? (
                      <div style={styles.error}>{errors.broadcast}</div>
                    ) : null}
                </Form.Group>
                <Form.Group style={{display: 'flex'}}>      
                  <div style={{marginRight: '3vw', width: '17vw'}}>
                    <Form.Label>Flag</Form.Label>     
                    <Form.Control as="select" value={flag} onChange={e => setFlag(e.target.value)}>
                      <option value="">None</option>
                      <option value="special">Special</option>
                      <option value="emergency">Emergency</option>
                    </Form.Control>
                    </div>
                </Form.Group>  
                <Form.Group>
                <Form.Label>Jira Id (Optional)(CSV)</Form.Label>
                    <Form.Control
                      type="input"
                      as="input"
                      className="data-cy-create-message-modal-notification-body"
                      value={jiraId}
                      onChange={(e) => setJiraId(e.target.value)}
                      onBlur={handleBlur}
                      name="jira_id"></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Notification Body</Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    className="data-cy-create-message-modal-notification-body"
                    value={notificationBody}
                    onChange={(e) => setNotificationBody(e.target.value)}
                    onBlur={handleBlur}
                    name="notification_body"></Form.Control>
                    {errors.notification_body && touched.notification_body ? (
                      <div style={styles.notification_body}>{errors.notification_body}</div>
                    ) : null}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Notification Title</Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    className="data-cy-create-message-modal-title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={handleBlur}
                    name="title"></Form.Control>
                    {errors.title && touched.title ? (
                      <div style={styles.title}>{errors.title}</div>
                    ) : null}
                </Form.Group>
                <Form.Group>
                <Form.Control as="select" multiple value={dataCenterField} onChange={e => setDataCenterField([].slice.call(e.target.selectedOptions).map(item => item.value))}>
                  {(data_center_dc_names).map((dc, index) => {
                      return (<option value={all_data_centers[index]['system_name']} id={dc} key={dc} name={dc}>{dc}</option>)
                    })}
                </Form.Control>
                </Form.Group>
              </div>
            </Modal.Body>
             
            
            <Modal.Footer>
              <div style={{display: 'contents'}}>
                <Button variant="secondary" onClick={closeModal} className="data-cy-create-message-modal-close" style={styles.buttonLeft}>Cancel</Button>
                <Button style={styles.buttonRight} className="data-cy-create-message-modal-ok" type="submit">Submit</Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
        </Formik>

      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(UpdateMaintenanceEventModal);