import React, {useEffect}      from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Row } from 'react-bootstrap';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import Loading            from '../../components/loading';
import ManageSecurityUsersTable from './Tables/ManageSecurityUsersTable';
import CreateSecurityUserModal from './Modals/CreateSecurityUserModal';
const mapStateToProps = ({securityIncidentState}) => {
  return {
    securityUsersLoading  : securityIncidentState.securityUsersLoading,
    securityUsers         : securityIncidentState.securityUsers,
  }
};
const mapDispatchToProps = (dispatch) => {
const combinedActionCreators = {
  requestSecurityUsers     : securityIncidentActions.requestSecurityUsers,
};
return bindActionCreators(combinedActionCreators, dispatch);
};
const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'width'        :  '73vw',
    'textAlign'    :  'center',
    'fontSize'     :  '2rem',
  },
};

const requiredAction = 'view_manage_security_users';
const ManageSecurityIncidentEmails = ({securityUsersLoading, requestSecurityUsers, securityUsers}) => {
  const { topContainer, pageTitle } = styles;
  useEffect(() => {
    requestSecurityUsers();
  }, []);

return (
  <AuthorizationWrapper requiredAction={requiredAction}>
    <div style={topContainer}>
      {
        securityUsersLoading
        ? <div>
            <div style={pageTitle}>Loading...</div>
            <Loading height="75vh" />
          </div>
        : <div>
            <Row>
              <div style={pageTitle}>Manage Security Incident Emails</div>
              <CreateSecurityUserModal  />
            </Row>
            <ManageSecurityUsersTable data={securityUsers}/>
          </div>
      }
    </div>


  </AuthorizationWrapper>
)
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSecurityIncidentEmails);