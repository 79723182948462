import React, {useEffect}      from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Button, Row } from 'react-bootstrap';
import { Link }     from 'react-router-dom';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import Loading            from '../../components/loading';
import EmailsTable          from './Tables/EmailsTable';
const mapStateToProps = ({securityIncidentState}, props) => {
  let commId;
  commId = props.match.params['id'];
  return {
    commId              : commId,
    emailsLoading : securityIncidentState.emailsLoading,
    commTypesLoading    : securityIncidentState.commTypesLoading,
    emails        : securityIncidentState.emails,
    commTypes           : securityIncidentState.commTypes,
  }
};

const mapDispatchToProps = (dispatch) => {
const combinedActionCreators = {
  requestEmails     : securityIncidentActions.requestEmails,
  requestCommTypes        : securityIncidentActions.requestCommTypes,
  setSpecificEmails : securityIncidentActions.setSpecificEmails,
};
return bindActionCreators(combinedActionCreators, dispatch);
};
const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'width'        :  '73vw',
    'textAlign'    :  'center',
    'fontSize'     :  '2rem',
  },
};

const requiredAction = 'view_emails';

const Emails = ({requestEmails, emailsLoading, commId, emails, setSpecificEmails}) => {
  const { topContainer, pageTitle } = styles;
  useEffect(() => {
    requestEmails(commId);
 
  }, []);

return (
  <AuthorizationWrapper requiredAction={requiredAction}>
    <div style={topContainer}>
      {
        emailsLoading
        ? <div>
            <div style={pageTitle}>Loading...</div>
            <Loading height="75vh" />
          </div>
        : <div>
            <Row>
              <div style={pageTitle}>emails for comm id {commId}</div>
              {<Link to={`/mass_import/`} style={{marginLeft: 'auto', marginRight: '1rem'}}><Button>Mass Import</Button></Link>}
              {<Link to={`/compose_email/${commId}`} style={{marginLeft: 'auto'}}><Button>Create Email</Button></Link>}
            </Row>
            <EmailsTable data={emails} setSpecificEmails={setSpecificEmails} />
          </div>
      }
    </div>


  </AuthorizationWrapper>
)
}

export default connect(mapStateToProps, mapDispatchToProps)(Emails);