import React    from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Row } from 'react-bootstrap';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import ComposeEmailTool          from './ComposeEmailTool';
const mapStateToProps = ({securityIncidentState}, props) => {
  let commId;
  let companyId;
  let id;
  commId = props.match.params['comm_id'];
  companyId = props.match.params['company_id'];
  id = props.match.params['id'];
  return {
    commId              : commId,
    companyId           : companyId,
    id                  : id,
    emailsLoading : securityIncidentState.emailsLoading,
    commTypesLoading    : securityIncidentState.commTypesLoading,
    emails        : securityIncidentState.emails,
    commTypes           : securityIncidentState.commTypes,
  
  }
};

const mapDispatchToProps = (dispatch) => {
const combinedActionCreators = {
  requestEmails              : securityIncidentActions.requestEmails,
  requestCommTypes                 : securityIncidentActions.requestCommTypes,
};
return bindActionCreators(combinedActionCreators, dispatch);
};
const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'width'        :  '73vw',
    'textAlign'    :  'center',
    'fontSize'     :  '2rem',
  },
};

const requiredAction = 'view_compose_email';

const ComposeEmail = ({id, commId, companyId}) => {
  const { topContainer, pageTitle } = styles;


return (
  <AuthorizationWrapper requiredAction={requiredAction}>
    <div style={topContainer}>
      <Row>
        <div style={pageTitle}>Compose Email</div>
      </Row>
      {
        <div>
          <ComposeEmailTool id={id} commId={commId} company_id={companyId} />
        </div>
      }
    </div>
  </AuthorizationWrapper>
)
}

export default connect(mapStateToProps, mapDispatchToProps)(ComposeEmail);