/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import propTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import http from '../../services/httpService';
import * as config from '../../config';
import PageVisibility from 'react-page-visibility';



/* ========= COMPONENT IMPORTS ========= */
import NotFound           from '../../components/NotFound/NotFound';
import Landing            from '../Landing/Landing';
import NavBar             from './NavBar/NavBar';
import IncidentDetailGrid from '../Details/IncidentDetailsGrid/IncidentDetailsGrid';
import History            from '../History/History';
import Customers          from '../CustomerDetails/CustomerDetails';
import ChangeHistory      from '../ChangeHistory/ChangeHistory';
import LoginPage          from './LoginPage/LoginPage';
import Subscriptions      from '../Subscriptions/Subscriptions';
import PrivateRoute       from './LoginPage/PrivateRoute';
import IncidentManagement from '../IncidentManagement/IncidentManagement';
import MaintenanceEvent   from '../MaintenanceEvent/MaintenanceEvent';
import ReviewMaintenanceEvent from '../MaintenanceEvent/ReviewMaintenanceEvent/ReviewMaintenanceEvent';
import V2MaintenanceEvent   from '../v2MaintenanceEvent/MaintenanceEvent';
import V2ReviewMaintenanceEvent from '../v2MaintenanceEvent/ReviewMaintenanceEvent/ReviewMaintenanceEvent';
import ReviewIncidentDetail from '../IncidentManagement/ReviewIncidentDetails/ReviewIncidentDetail';
import Administration     from '../Administration/Administration'
import TemplateManagement from '../Templates/TemplateManagement';
import Dora from '../Dora/Dora';
import Charts from '../Charts/Charts';
import SubscriberSearch from '../CommHistory/SubscriberSearch';
import SubscriberDetails from '../CommHistory/SubscriberDetails';
import SecurityIncidents from '../SecurityIncidents/SecurityIncidents';
import Comms from '../SecurityIncidents/Comms';
import Emails from '../SecurityIncidents/Emails';
import ComposeEmail from '../SecurityIncidents/ComposeEmail';
import ManageSecurityUsers from '../SecurityIncidents/ManageSecurityUsers';
import MassImport from '../SecurityIncidents/MassImport';
import StagedEmails from '../SecurityIncidents/StagedEmails';

import { userProptypes, actions as userActions } from '../../ducks/user/user.index';
import { uiProptypes, actions as uiActions } from '../../ducks/ui/ui.index';
import { actions as severityActions } from '../../ducks/severity/severity.index';
import { actions as incidentActions } from '../../ducks/incident/incident.index';
import { actions as maintenanceEventActions } from '../../ducks/maintenanceEvent/maintenanceEvent.index';
import { actions as v2MaintenanceEventActions } from '../../ducks/v2MaintenanceEvent/v2MaintenanceEvent.index';
import { actions as templateActions } from '../../ducks/template/template.index';
import { actions as doraActions } from '../../ducks/dora/dora.index';

/* ========= CSS IMPORTS ========= */
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import 'react-toastify/dist/ReactToastify.css';
import '../../index.css';
import './App.css';


/* ========= REDUX IMPORTS ========= */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from '../../components/loading';
import DisclaimerModal from './DisclaimerModal/DisclaimerModal';
import NetworkDetector from '../../components/NetworkDetector/NetworkDetector';

// import UnderConstruction from './UnderConstruction/UnderConstruction';


/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({userState, uiState}) => ({
  userState: {
    user       :  userState.user,
    fetching   :  userState.fetching,
    isLoggedIn :  userState.isLoggedIn,
    isLoading  :  userState.isLoading,
  },
  uiState: {
    activeLocales: uiState.activeLocales,
    displayLocale: uiState.displayLocale,
  },
    browserWindowIsVisible  :  uiState.browserWindowIsVisible,
    displayLocale           :  uiState.displayLocale,
    activeLocales           :  uiState.activeLocales
});
const mapDispatchToProps = (dispatch) => {
  const combineActionCreators = {
    logoutUser                    : userActions.logoutUser,
    requestUser                   : userActions.requestUser,
    userStatus                    : userActions.setUserLoggedIn,
    setUser                       : userActions.setUser,
    processUser                   : userActions.processUser,
    setUserLoading                : userActions.setUserLoading,
    setPageFocus                  : uiActions.setPageFocus,
    setWindowDimensions           : uiActions.setWindowDimensions,
    getSeverityValues             : severityActions.requestSeverities,
    requestEmailFields            : incidentActions.requestEmailFields,
    requestP2AlertTypes           : incidentActions.requestP2AlertTypes,
    requestBannerState            : incidentActions.requestBannerState,
    requestActiveLocales          : uiActions.requestActiveLocales,
    setDisplayLocale              : uiActions.setDisplayLocale,
    requestAllDataCenters         : maintenanceEventActions.requestAllDataCenters,
    requestMaintenanceEvents      : maintenanceEventActions.requestMaintenanceEvents,
    V2RequestMaintenanceEvents    : v2MaintenanceEventActions.V2RequestMaintenanceEvents,
    V2RequestActiveNotifications  : v2MaintenanceEventActions.V2RequestActiveNotifications,
    requestTemplates              : templateActions.requestTemplates,
    requestAllDoraDataCenters     : doraActions.requestAllDataCenters,
  };
  return bindActionCreators(combineActionCreators, dispatch);
};


class App extends Component {
  state = {};
  static propTypes =  {
    userState: propTypes.shape({
      user        : userProptypes.user,
      fetching    : userProptypes.fetching,
      isLoggedIn  : userProptypes.isLoggedIn,
      loading     : userProptypes.isLoading,
    }),
    uiState: propTypes.shape({
      activeLocales: uiProptypes.activeLocales,
    }),
    browserWindowIsVisible  : uiProptypes.browserWindowIsVisible,
    setPageFocus            : uiProptypes.setPageFocus,
    setWindowDimensions     : uiProptypes.setWindowDimensions,

  };

  componentDidMount = async () => {
    const {setUserLoading, getSeverityValues, requestEmailFields, requestP2AlertTypes, V2RequestMaintenanceEvents,V2RequestActiveNotifications,requestMaintenanceEvents, requestBannerState, requestActiveLocales, requestAllDataCenters, requestTemplates, requestAllDoraDataCenters} = this.props;
    this.addResizeListener();
    await this.handleAuth();
    getSeverityValues();
    V2RequestMaintenanceEvents();
    V2RequestActiveNotifications();
    requestMaintenanceEvents();
    requestEmailFields();
    requestP2AlertTypes();
    requestActiveLocales();
    requestAllDataCenters();
    requestTemplates();
    requestBannerState();
    requestAllDoraDataCenters();
    setUserLoading(false);
  }

  addResizeListener = () => {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    return;
  }

  async handleAuth() {
    const {setUser, processUser} = this.props;
    if(config.REACT_APP_AUTH_EMAIL_ADDRESS && !window.Cypress) {
      // Email address provided in environment (local development, non-Cypress). Assume this identity for front-end.
      processUser(config.LOCAL_USER);
    } else {
      // Normal workflow; post to introspect for email address
      try {
        const response = await http.post("/get_user_id/");
        if (typeof response.data.payload.data["email"] !== "undefined") {
          // Introspect validated the cookie passed and returned user profile
          processUser(response.data.payload.data);
        } else {
          // No server error, but could not verify user's identity; tell front-end to log the user out to ensure a redirect to /login
          this.props.logoutUser();
        }
      } catch(error) {
        // Server error. Could not verify user's identity; tell front-end to log the user out to ensure a redirect to /login
        this.props.logoutUser();
      }
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  }

  handlePageFocus = () =>{
    this.props.setPageFocus(this.props.browserWindowIsVisible)
  }

  updateDimensions = () => {
    this.props.setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    const { userState, uiState } = this.props;

    return (

        <PageVisibility onChange={this.handlePageFocus}>
          <div className="app">
            {userState.isLoggedIn && <DisclaimerModal />}
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <BrowserRouter>
              <React.Fragment>
                <ToastContainer />
                <NavBar userState={userState} uiState={uiState} logout={this.props.logoutUser} setDisplayLocale={this.props.setDisplayLocale} />
                {userState.isLoading ? <Loading /> :
                  <main className="container">
                  <NetworkDetector />
                    {/* <div className="inner-container"> */}
                    <Switch>
                        <Route path="/not-found" component={NotFound} title="Not Found" />
                        <PrivateRoute path="/changes" isAuthenticated={userState.isLoggedIn} userState={userState} component={ChangeHistory} title="change_history" />
                        <PrivateRoute path="/details/:id" isAuthenticated={userState.isLoggedIn} component={IncidentDetailGrid} title="details" />
                        <PrivateRoute path="/details" isAuthenticated={userState.isLoggedIn} component={IncidentDetailGrid} title="details" />
                        <PrivateRoute path="/history" isAuthenticated={userState.isLoggedIn} component={History} />
                        <PrivateRoute path="/subscriptions" isAuthenticated={userState.isLoggedIn} component={Subscriptions} />
                        <PrivateRoute path="/customers" isAuthenticated={userState.isLoggedIn} component={Customers} />
                        <PrivateRoute path="/incident/review" isAuthenticated={userState.isLoggedIn} userState={userState} component={IncidentManagement} isRestricted={false} title="incident_management"/>
                        <PrivateRoute path="/dora/:data_center" isAuthenticated={userState.isLoggedIn} userState={userState} component={Dora} title="dora"/>
                        <PrivateRoute path="/charts" isAuthenticated={userState.isLoggedIn} userState={userState} component={Charts} title="charts"/>
                        <PrivateRoute path="/comm_history/u/:email" isAuthenticated={userState.isLoggedIn} userState={userState} component={SubscriberDetails} title="SubscriberDetails"/>
                        <PrivateRoute path="/comm_history" isAuthenticated={userState.isLoggedIn} userState={userState} component={SubscriberSearch} title="SubscriberSearch"/>
                        <PrivateRoute path="/security_incidents" isAuthenticated={userState.isLoggedIn} userState={userState} component={SecurityIncidents} title="security_incidents"/>  
                        <PrivateRoute path="/comms/:id" isAuthenticated={userState.isLoggedIn} userState={userState} component={Comms} title="comms"/>  
                        <PrivateRoute path="/emails/:id" isAuthenticated={userState.isLoggedIn} userState={userState} component={Emails} title="emails"/>  
                        <PrivateRoute path="/compose_email/:comm_id/:company_id/:id" isAuthenticated={userState.isLoggedIn} userState={userState} component={ComposeEmail} title="compose_email"/>  
                        <PrivateRoute path="/compose_email/:comm_id" isAuthenticated={userState.isLoggedIn} userState={userState} component={ComposeEmail} title="compose_email"/>  
                        <PrivateRoute path="/mass_import/" isAuthenticated={userState.isLoggedIn} userState={userState} component={MassImport} title="mass_import"/>  
                        <PrivateRoute path="/staged_emails/:import_id" isAuthenticated={userState.isLoggedIn} userState={userState} component={StagedEmails} title="staged_emails"/>  
                        <PrivateRoute path="/edit/:id" isAuthenticated={userState.isLoggedIn} userState={userState} component={ReviewIncidentDetail} isRestricted={false} title="incident_management"/>
                        <PrivateRoute path="/maintenance_event/review/:id" isAuthenticated={userState.isLoggedIn} userState={userState} component={V2ReviewMaintenanceEvent} isRestricted={false} title="maintenance_events"/>
                        <PrivateRoute path="/maintenance_event" isAuthenticated={userState.isLoggedIn} userState={userState} component={V2MaintenanceEvent} isRestricted={false} title="maintenance_events"/>
                        <PrivateRoute path="/template_management/:type" isAuthenticated={userState.isLoggedIn} userState={userState} component={TemplateManagement} isRestricted={false} title="template_management"/>
                        <PrivateRoute path="/manage_security_users/" isAuthenticated={userState.isLoggedIn} userState={userState} component={ManageSecurityUsers} title="manage_security_users"/>  
                        <Route path="/administration" userState={userState} component={Administration} title="administration"/>

                        <Route path="/home" isAuthenticated={userState.isLoggedIn} render={(props) => <LoginPage {...props} email={userState.isLoggedIn} />}/>
                        <PrivateRoute path="/" isAuthenticated={userState.isLoggedIn} exact component={Landing} title="Active Incidents"/>
                        <Route path="/login" component={(props) => {
                          // Redirect user to Flokta for login
                          let exactPath = props.location.hasOwnProperty('from') ? props.location.from : "";
                          if(config.REACT_APP_AUTH_EMAIL_ADDRESS) {
                            // This means you're developing locally; redirect to /home to simulate normal experience (but without auth)
                            window.location.href = '/home'
                          } else {
                            // This is the normal workflow: redirect to Flokta for login
                            window.location.href = config.REACT_APP_FLOKTA_URL + '/authorize?redirect=' + config.REACT_APP_FLOKTA_REDIRECT_URL + exactPath;
                          }
                          return null;
                        }}/>

                        <Route path="/not-found" component={NotFound} />
                        <Redirect from="/" exact to="/" />
                        <Redirect to="/not-found" />
                      </Switch>
                    {/* </div> */}
                  </main>
                }
              </React.Fragment>
            </BrowserRouter>
          </div>
        </PageVisibility>

    );
  }
}

const ECSapplication = '';
export default withCookies(connect(mapStateToProps, mapDispatchToProps)(App));



