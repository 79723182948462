export const dateTimeToUTCString = (dateString) => {
  if (!dateString) return "";
  return new Date(dateString).toUTCString();
} // 2019-04-01T14:20:00.000Z => Mon, 01 Apr 2019 14:20:00 GMT

export const dateTimeToPSTString = (dateString) => {
  if (!dateString) return "";
  let options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short', timeZone: 'America/Los_Angeles'};
  return new Date(dateString).toLocaleString('en-US', options);
} // 2019-04-01T14:20:00.000Z => Sat, 5/7/2022, 7:00 AM PDT

export const turnObjectArrayToArray = (objArray, paramName) => {
  let returnArray = [];
  objArray.map((obj) => {
    returnArray.push(obj[paramName]);
  })
  return returnArray;
}

export const epochTimeToUTCString = (epochTime) => {
  if (!epochTime) return "";
  return new Date(epochTime * 1000).toUTCString();
}

export const joinArrayToString = (arr) => arr.join(', '); // ["Travel", "Imaging", "Invoice"] => Travel, Imaging, Invoice

export const joinObjectArrayToString = (arr) => {
  let output = '';
  arr.map ((value) => {
    output += value.name + ', '
  })
  output = output.slice(0, -2);
  return output;
}

// help from https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
export const formatNumberWithDelimiters = (number) => {
  let output;
  if (typeof number !== 'number' ) {
    output = NaN;
  } else {
    output = number.toString().replace(/\B(?=(\d{3})+(?!\d))/gu, ',');
  }
  return output;
};
// 1234 => 1,234
// 3268424 => 3,268,424

export const truncateString = (str, characterLimit) => {
  // Will truncate the input string based on the characterLimit given. If input = ("Hello, this is a test", 12) output will be "Hello, th..."

  const ending = '...';
  let output;
  if (str.length > characterLimit) {
    output = str.substring(0, characterLimit - 3) + ending;
  } else {
    output = str;
  }
  return output;
};


export const formatDurationToNearestMinute = (duration) => {
  // duration comes in as a string "1:00:05" (1 hour and 5 seconds) and rounds to nearest minute. Output would be "1 hour"
  // Other examples: "10:26" => "10 minutes", "3:01:08" => "3 hours, 1 minute"

  const d = duration.split(':');
  let output;
  let minute;
  let minuteLabel;
  let hour;
  let hourLabel;
  let day;
  let dayLabel;

  switch (d.length) {
    case 2: // after split, if array length is 2, we are working with minutes and seconds
      [minute] = d; // Array destructuring
      minute = minute.replace(/^0+/u, '');
      minuteLabel = minute === '1' ? 'minute' : 'minutes'; // if duration = 1:02, output = "1 minute"
      output = `${minute} ${minuteLabel}`; // if duration = 12:04, output = "12 minutes"
      break;

    case 3: // after split, if length is 3, we are working with hours, minutes, and seconds
      [hour, minute] = d; // Array destructuring
      hour = hour.replace(/^0+/u, '');
      hourLabel = hour === '1' ? 'hour' : 'hours'; // if duration = 1:01:02, output = "1 hour, 1 minute"
      minute = minute.replace(/^0+/u, '');
      minuteLabel = minute === '1' ? 'minute' : 'minutes';
      minute = minute ? `, ${minute} ${minuteLabel}` : ''; // if there are any minutes, display them, if not display nothing
      output = `${hour} ${hourLabel}${minute}`; // if duration = 6:14:02, output = "6 hours, 14 minutes"
      break;

    case 4: // after split, if length is 4, we are working with days, hours, minutes, and seconds
      [day, hour, minute] = d; // Array destructuring
      dayLabel = day === '1' ? 'day' : 'days';
      hour = hour.replace(/^0+/u, '');
      hourLabel = hour === '1' ? 'hour' : 'hours';
      minute = minute.replace(/^0+/u, '');
      minuteLabel = minute === '1' ? 'minute' : 'minutes';
      hour = hour ? `, ${hour} ${hourLabel}` : ''; // if there are any hours, display them, if not display nothing
      minute = minute ? `, ${minute} ${minuteLabel}` : ''; // if there are any minutes, display them, if not display nothing
      output = `${day} ${dayLabel}${hour}${minute}`;
      break;

    default:
      output = d; // if input time is in the week time frame, Moment renders this correctly
      break;
  }
  return output;
};

// used to shorten service name down to just the first word for cypress tests
// if input = 'Analysis/Intelligence' => 'Analysis' | if input = 'Compleat (TMC Services)' => 'Compleat'
export const getShortName = (serviceName) => {
  let truncName = ""

  if (serviceName === 'Analysis/Intelligence') {
    truncName = 'Analysis';
  } else if (serviceName === 'Compleat (TMC Services)') {
    truncName = 'Compleat';
  // These two are used in tests
  } else if (serviceName === 'Another(newService)') {
    truncName = 'Another';
  } else if (serviceName === 'New-Service') {
    truncName = 'New';
  // default behavior
  } else {
    truncName = serviceName;
  }

  return truncName
};

export const handleTypeFormat = (input) => {
  const type = typeof input;
  let output = input;
  if (Array.isArray(input)) {
    output = joinArrayToString(input);
  } else if (type === 'number') {
    output = formatNumberWithDelimiters(input);
  } else if (type === 'object') {
    output = input;
  } else if (input.match(/[0-9]{4}-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}(T| )?[0-2]{1}[0-9]{1}:[0-9]{2}:[0-9]{2}((.[0-9]{0,4}:[0-9]{0,2})|(.[0-9]{0,4}))?Z?$/gu)) {
    /*
      matches UTC date string
      2019-09-25T16:59:00
      2019-09-25T16:59:00Z
      2019-09-25T16:59:00+0000
      2019-09-25T16:59:00+0000Z
      2019-09-25 19:59:00+00:00
      2019-09-25 19:59:00+00:00Z
    */
    output = dateTimeToUTCString(input);
  }
  return output;
};

export const formatIncidentData = (incidentDetails) => {
  const modifiedIncident = {...incidentDetails};
  if (modifiedIncident == null) return {};
  Object.keys(modifiedIncident).forEach((key)=> modifiedIncident[key] = handleTypeFormat(modifiedIncident[key]))
  return modifiedIncident;
};

export const convertStringToSearchArray = (value) => {
  return typeof value == 'string' ? value
    .split(',')
    .map((item) => item.trim().toLowerCase()) // Remove whitespace and set to lower case
    .filter((item) => item != "")             // Remove empty inputs (trailing commas cause this)
  : [];
};

export const sortSymbol = (a,b) => {
  const severity_map = {
    "NORMAL"              : 0,
    "PARTIAL_PERFORMANCE" : 1,
    "PERFORMANCE"         : 2,
    "PARTIAL_OUTAGE"      : 3,
    "OUTAGE"              : 4,
    "degradation"         : 3,
    "disruption"          : 4,
  };

  const x = severity_map[a];
  const y = severity_map[b];
  return x - y;
};

export const sortOpi = (a,b) => {
  const x = a.split('-').pop();
  const y = b.split('-').pop();
  return x - y;
};

export const sortDate = (a,b) => {
  const x = new Date(a).getTime();
  const y = new Date(b).getTime();
  return x - y;
};

export const sortNumber = (a,b) => {
  return a - b;
};

export const sortPriorityType = (a,b) => {
  const priority_map = {
    "P1" : 0,
    "P2" : 1,
  };
  const x = priority_map[a];
  const y = priority_map[b];
  return x - y;
};

export const getActiveStatus = (startDate, endDate, status) => {
  if (status === "RESOLVED") return false
  if (!endDate) return true
  const now = new Date()
  return (now.getTime() <= new Date(endDate).getTime() && now.getTime() >= new Date(startDate).getTime())
}
