import React,  {useState, Fragment}       from 'react';
import { useTable, useSortBy } from 'react-table'
import { Table } from 'react-bootstrap';
import { Link }     from 'react-router-dom';
import { MdExpandMore } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import ShowCsvData from '../Modals/ShowCsvData';
import DeleteModalTemplate from '../Modals/DeleteModalTemplate';

import {
  dateTimeToUTCString,
  sortOpi,
  sortDate,
} from '../../../utils/formatFunctions'
import { actions as securityIncidentActions } from '../../../ducks/securityIncidents/securityIncident.index';
const styles = {
  'tableContainer': {
    'fontSize'        :  '.94rem',
    'boxSizing'       :  'border-box',
    'borderRadius'    :  '4px',
    'backgroundColor' :  'rgb(250, 250, 250)',
    'boxShadow'       :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  'evenRow':{
    'backgroundColor': '#007bff2e'
  },
  'noData': {
    'textAlign': 'center',
  }
};

const mapStateToProps = () => ({
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    requestDeleteImports :  securityIncidentActions.requestDeleteImports,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};



function RenderTable({ columns, values }) {
  const { t } = useTranslation(["translation"]);

  const data = React.useMemo(() => 
  values
  , [values]);

  const sorting = React.useMemo(
    () => [
      {
        id: "created_date",
        desc: true
      }
    ],
    []
  );
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : '';
  };

  const widthDict = {
    'sec_comm_id': 122,
    'comm_type': 260,
    'description': 300,
    'created_date': 230,
    'status': 230
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sorting,
      }
    },
    useSortBy,
  )
  return (
    <Fragment>
      <Table bordered {...getTableProps()} className="cy-history-table">  
        <thead>
          {headerGroups.map(headerGroup => (
            <tr className='rt-thead' {...headerGroup.getHeaderGroupProps()} >
              {headerGroup.headers.map((column, i) => {
                const {render, getHeaderProps} = column
                return (
                  <th style={{ width: column.size || widthDict[column.id] }} className={generateSortingIndicator(column) + " " + column.id + "-header"} key={'header' + i} {...getHeaderProps(column.getSortByToggleProps())}>{render("Header")}</th>
                )
              })}
            </tr>
          ))}
        </thead>
        {rows.length > 0 && <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {        
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr style={index % 2 == 0 ? styles.evenRow : {}}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>}
        {rows.length == 0 &&  <>
          <tbody>
            {
              [...Array(7)].map((e, i) => {
                if(i == 3){
                  return(<tr key={'row-' + i} style={styles.noData} className='rt-noData'><td colSpan={columns.length}>No Data</td></tr>)
                } 
                else{
                  return (<tr key={'row-' + i}>{columns.map((e, j) => (<td key={'col-' + j}/>))}</tr>)
                }
              })
            }
          </tbody>
        </>}
      </Table>
     
    </Fragment>
  );
}



const ImportsTable = ({data, requestDeleteImports}) => {


  const { headers, tableContainer } = styles;
  const { t } = useTranslation(["translation"]);
  const [rowData, setRowData] = useState(data);
  if (document.getElementsByClassName("-center")[0]){
    document.getElementsByClassName("-center")[0].style.display = 'none'
  }

  const columns = React.useMemo(
    () => [
        {
          Header: <b style={styles.test} data-cy='id-header'>Import ID</b>,
          headerStyle: headers,
          id: 'pkey',
          size: '122px',
          Cell          : ({value}) => <div>{value}</div>,
          accessor      : "pkey",
          sortMethod    : (a, b) => sortOpi(a, b),
        },
        {
          Header      : <b data-cy="internal-header">Security Incident Id</b>,
          headerStyle : headers,
          id          : 'sec_comm_id',
          size       : '50px',
          accessor    : data => <div style={{width: '50px'}}>{(data.sec_comm_id)}</div>,
        },
        {
          Header: <b data-cy='service-header'>Title</b>,
          headerStyle: headers,
          id: 'title',
          accessor    : data => <div style={{width: '100px'}}>{data.title}</div>,
          size: '260px',
        },
       
        {
          Header      : <b data-cy="internal-header">Data</b>,
          headerStyle : headers,
          id          : 'data',
          size       : '100px',
          accessor    : data => <ShowCsvData data={data.data}/>
        },
        {
          Header: <b data-cy='service-header'>FileName</b>,
          headerStyle: headers,
          id: 'filename',
          accessor    : data => <div style={{width: '100px'}}>{data.filename}</div>,
          size: '260px',
        },
        {
          Header: <b data-cy="created-date-header">Import Date</b> ,
          headerStyle: headers,
          id: 'created_date',
          accessor: 'created_date',
          size: '230px',
          Cell      : ({value}) => (<div style={{width: '230px'}}>{dateTimeToUTCString(value)}</div>),
          sortMethod    :  (a, b) => sortDate(a, b),
        },
        {
          Header      : <b data-cy="internal-header">Imported By</b>,
          headerStyle : headers,
          id          : 'created_by',
          size       : '100px',
          accessor    : data => <div style={{width: '100px'}}>{data.created_by}</div>,
        },
        {
          Header: <b data-cy='data-center-header'>View</b>,
          headerStyle: headers,
          id: 'pkey3',
          width: 50,
          accessor: (data) => <Link to={`/staged_emails/${data.pkey}`} style={{marginLeft: '10px'}} ><MdExpandMore style={{fontSize: "35px"}}/></Link>
        },
        {
          Header: <b data-cy='data-center-header'>Delete</b>,
          headerStyle: headers,
          id: 'pkey4',
          width: 50,
          accessor: (data) => <DeleteModalTemplate 
          deleteFunction={requestDeleteImports}
          delete_id={data.pkey} 
          data={data}
          keys={["pkey", "sec_comm_id", "title", "file_name", "created_date", "created_by"]}
          displayKeys={["Import ID", "Comm ID", "Title", "File Name", "Created Date", "Created By"]} 
          type="Imports"
          html_modal={false} />
        },
      ]
    )

  return (
    <div style={tableContainer}>
      <RenderTable columns={columns} values={rowData} />
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(ImportsTable);
