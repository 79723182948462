import React, {useEffect}      from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Button, Row } from 'react-bootstrap';
import { Link }     from 'react-router-dom';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import Loading            from '../../components/loading';
import ImportsTable from './Tables/ImportsTable';
import CreateImportModal from './Modals/CreateImportModal';

const mapStateToProps = ({securityIncidentState}) => {
  return {
    importsLoading : securityIncidentState.importsLoading,
    imports: securityIncidentState.imports,
  }
};

const mapDispatchToProps = (dispatch) => {
const combinedActionCreators = {
  requestImports : securityIncidentActions.requestImports,
  requestComms        : securityIncidentActions.requestComms,
  requestSecurityIncidents : securityIncidentActions.requestSecurityIncidents,
  requestCommTypes: securityIncidentActions.requestCommTypes

};
return bindActionCreators(combinedActionCreators, dispatch);
};
const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'width'        :  '73vw',
    'textAlign'    :  'center',
    'fontSize'     :  '2rem',
  },
};

const requiredAction = 'view_mass_import';

const MassImport = ({requestImports, requestSecurityIncidents, requestCommTypes, importsLoading, imports}) => {
  const { topContainer, pageTitle } = styles;
  useEffect(() => {
    requestImports();
    requestCommTypes();
    requestSecurityIncidents();
  }, []);

return (
  <AuthorizationWrapper requiredAction={requiredAction}>
    <div style={topContainer}>
      {
        importsLoading
        ? <div>
            <div style={pageTitle}>Loading...</div>
            <Loading height="75vh" />
          </div>
        : <div>
            <Row>
              <div style={pageTitle}>Mass Import</div>
              <CreateImportModal />
            </Row>
            <ImportsTable data={imports} />
          </div>
      }
    </div>


  </AuthorizationWrapper>
)
}

export default connect(mapStateToProps, mapDispatchToProps)(MassImport);