import React, {useEffect, useState}      from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdOutlineMode } from "react-icons/md";
import Loading            from '../../../components/loading';
import DOMPurify from 'dompurify';
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import { Modal, Button, Tooltip, OverlayTrigger, Card, Row, Form } from 'react-bootstrap';
import {parseHtmlFromDb, htmlToString} from '../SecurityIncidentHelpers';
import UpdateComposeEmailModal from './UpdateComposeEmailModal';
import DeleteComposeEmailModal from './DeleteComposeEmailModal';
import CreateComposeEmailModal from './CreateComposeEmailModal';
import './../ComposeEmailTool.css';
/* ========= PACKAGE IMPORTS ========= */

const mapStateToProps = ({securityIncidentState}) => ({
  emailContents         : securityIncidentState.emailContents,
  emails                : securityIncidentState.emails,
  comms                 : securityIncidentState.comms,
  allCommIds            : securityIncidentState.allCommIds,
  specificEmailsLoading : securityIncidentState.specificEmailsLoading,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setEmailContents                  : securityIncidentActions.setEmailContents,
    requestSpecificEmails             : securityIncidentActions.requestSpecificEmails,
    setEmailPreviewLoading            : securityIncidentActions.setEmailPreviewLoading,
    requestEditStagingEmails          : securityIncidentActions.requestEditStagingEmails,
    requestComms                      : securityIncidentActions.requestComms,
    directlySetAllCommIds             : securityIncidentActions.directlySetAllCommIds,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const UpdateStagedEmailModal = ({data, requestEditStagingEmails, requestComms, setEmailContents, directlySetAllCommIds, emailContents, specificEmailsLoading, comms, allCommIds}) => {
  const [modalIsOpen,setIsOpen] = useState(false);
  const [subject, setSubject] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [secId, setSecId] = useState('');
  useEffect(() => {
    requestComms();
    if (data && data.body !== "" && modalIsOpen){
      setEmailContents(parseHtmlFromDb(null, data.body));
    }
    if (data && data.sec_comm_id && modalIsOpen){
      directlySetAllCommIds([]);
      comms.forEach((comm) => {
        if (comm.sec_comm_id === data.sec_comm_id){
          setSecId(comm.sec_id);
        }
      });
      if (secId !== ''){
        requestComms(secId);
      }
    }
  }, [modalIsOpen]);

  const openModal = () => {
    setIsOpen(true);

  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const submitSend = () => {
    setErrorMsg('');
    let stringHtml = htmlToString(emailContents);
    if (stringHtml === ""){
      setErrorMsg("Email body cannot be empty");
      return;
    }
    if (data.subject === ""){
      setErrorMsg("Subject cannot be empty");
      return;
    }
    data.body = stringHtml;
    data.id = data.pkey;
    requestEditStagingEmails(data);
    closeModal();
  }

  const onChangeSubject = (value) => {
    setSubject(value);
    data['subject'] = value;
  }

  const setSecCommId = (value) => {
    data['sec_comm_id'] = value;
  }

  return (
    <div style={{display: 'unset'}}>
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Preview email with option to send </Tooltip>
        }
      >
        <MdOutlineMode style={{fontSize: '35px', color: '#007bff'}} onClick={openModal}/>
      </OverlayTrigger>
      
      <Modal size='lg' show={modalIsOpen} onHide={closeModal} className="data-cy-preview-email-modal">
      <div style={{textAlign: 'center'}}>
          <Modal.Header closeButton>
            <Modal.Title>Update Staged Email</Modal.Title>
          </Modal.Header>
          <Card>
            <Card.Body >
              <Form>
                <Row>
                  <div style={{float: 'left', marginLeft: "5rem"}}>Subject: <input style={{width: '407px'}} type="text" value={data.subject} onChange={e => onChangeSubject(e.target.value)}/></div>
                </Row>
                <Row style={{marginTop: '5px'}} >
                  <Form.Label style={{marginLeft: '72px'}}>Comm Id: </Form.Label>  
                  <Form.Control style={{width: '10vw', height: '2rem'}} as="select" value={data.sec_comm_id} onChange={e => setSecCommId(e.target.value)}>
                    {allCommIds.map((comm_id, index) => {
                      return (
                        <option key={"comm" + index} value={comm_id}>{comm_id}</option>
                      )
                    })}
                  </Form.Control>
                </Row>
              </Form>
              <br/>
              <div style={{textAlign: 'center', display: 'grid'}}>
                {emailContents.map((element, index) => {
                  return (
                    <span style={{display: 'ruby'}} key={"updatestagedemail" + index} className="Container">
                      {element}
                      <span style={{marginLeft: '5px', verticalAlign: 'top'}} className="header">
                        <UpdateComposeEmailModal index={"update" + index}/>
                        <DeleteComposeEmailModal index={"delete" + index}/>
                      </span>
                    </span>
                  )})
                }
              </div>
            
            </Card.Body>
          </Card>
          <div style={{color: 'red'}}>{errorMsg}</div>
          <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: '5px'}}>
            <CreateComposeEmailModal key="1" style={{marginRight: '1rem'}} type="table" />
            <CreateComposeEmailModal key="2" style={{marginRight: '1rem'}} type="paragraph" />
            <CreateComposeEmailModal key="3" type="header" />
          </div>
          <div style={{display: 'flex'}}>
            <Button variant="secondary" onClick={closeModal} className="data-cy-preview-email-modal-close" style={{margin: '1vw', width: '8rem', marginRight: 'auto'}}>Cancel</Button>
            <Button variant="primary" onClick={() => submitSend()} style={{float: 'right', width: '8rem', margin: '1vw'}}>Save</Button>
          </div>
          
        </div>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(UpdateStagedEmailModal);