import React, {useEffect}      from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Button, Row } from 'react-bootstrap';
import { Link }     from 'react-router-dom';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import Loading            from '../../components/loading';
import StagingEmailsTable from './Tables/StagingEmailsTable';

const mapStateToProps = ({securityIncidentState}, props) => {
  let importId;
  importId = props.match.params['import_id'];
  return {
    importId: importId,
    stagingEmailsLoading : securityIncidentState.stagingEmailsLoading,
    stagingEmails: securityIncidentState.stagingEmails,
  }
};

const mapDispatchToProps = (dispatch) => {
const combinedActionCreators = {
  requestStagingEmails: securityIncidentActions.requestStagingEmails,

};
return bindActionCreators(combinedActionCreators, dispatch);
};
const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'width'        :  '73vw',
    'textAlign'    :  'center',
    'fontSize'     :  '2rem',
  },
};

const requiredAction = 'view_staging_emails';

const StagedEmails = ({requestStagingEmails, importId, stagingEmailsLoading, stagingEmails}) => {
  const { topContainer, pageTitle } = styles;
  useEffect(() => {
    if (importId){
      requestStagingEmails(importId);
    }
  }, [importId]);

return (
  <AuthorizationWrapper requiredAction={requiredAction}>
    <div style={topContainer}>
      {
        stagingEmailsLoading
        ? <div>
            <div style={pageTitle}>Loading...</div>
            <Loading height="75vh" />
          </div>
        : <div>
            <Row>
              <div style={pageTitle}>Mass Import</div>
            </Row>
            <StagingEmailsTable data={stagingEmails} />
          </div>
      }
    </div>


  </AuthorizationWrapper>
)
}

export default connect(mapStateToProps, mapDispatchToProps)(StagedEmails);