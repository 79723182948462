import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdDeleteForever } from "react-icons/md";
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import { Modal, Button, Tooltip, OverlayTrigger, Card } from 'react-bootstrap';
/* ========= PACKAGE IMPORTS ========= */

const mapStateToProps = ({securityIncidentState}) => ({
  emailContents       : securityIncidentState.emailContents,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setEmailContents                         : securityIncidentActions.setEmailContents,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const DeleteComposeEmailModal = ({emailContents, setEmailContents, index}) => {
  const [modalIsOpen,setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const submitDelete = () => {
    emailContents.splice(index, 1);
    setEmailContents(emailContents);
    closeModal();
  }

  return (
    <div>
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Deletes this email line</Tooltip>
        }
      >
        <MdDeleteForever onClick={openModal}/>
      </OverlayTrigger>
      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-delete-compose-email-modal">
        <Modal.Header closeButton>
          <Modal.Title>Delete Line</Modal.Title>
        </Modal.Header>
        <Card>
          <Card.Body>
            <div>Confirm: Are you sure you want to delete this?</div>
            {emailContents[index]}
          </Card.Body>
        </Card>
        <div style={{display: 'flex'}}>
          <Button variant="secondary" onClick={closeModal} className="data-cy-delete-compose-email-modal-close" style={{margin: '1vw', width: '8rem', marginRight: 'auto'}}>Cancel</Button>
          <Button variant="danger" onClick={() => submitDelete()} style={{float: 'right', width: '8rem', margin: '1vw'}}>Delete</Button>
        </div>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(DeleteComposeEmailModal);