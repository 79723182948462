import React, {useEffect}      from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Row } from 'react-bootstrap';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import Loading            from '../../components/loading';
import CommTable          from './Tables/CommTable';
import CreateCommModal from './Modals/CreateCommModal';

const mapStateToProps = ({securityIncidentState}, props) => {
  let securityIncidentId;
  securityIncidentId = props.match.params['id'];
  return {
    securityIncidentId: securityIncidentId,
    commsLoading      : securityIncidentState.commsLoading,
    commTypesLoading      : securityIncidentState.commTypesLoading,
    comms             : securityIncidentState.comms,
    commTypes: securityIncidentState.commTypes,
  }
};

const mapDispatchToProps = (dispatch) => {
const combinedActionCreators = {
  requestComms              : securityIncidentActions.requestComms,
  requestCommTypes          : securityIncidentActions.requestCommTypes,
};
return bindActionCreators(combinedActionCreators, dispatch);
};
const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'width'        :  '73vw',
    'textAlign'    :  'center',
    'fontSize'     :  '2rem',
  },
};

const requiredAction = 'view_comms';

const Comms = ({requestComms, requestCommTypes, commsLoading, commTypesLoading, securityIncidentId, comms, commTypes}) => {
  const { topContainer, pageTitle } = styles;
  useEffect(() => {
    requestComms(securityIncidentId);
    requestCommTypes();
  }, []);

return (
  <AuthorizationWrapper requiredAction={requiredAction}>
    <div style={topContainer}>
      {
        commsLoading || commTypesLoading
        ? <div>
            <div style={pageTitle}>Loading...</div>
            <Loading height="75vh" />
          </div>
        : <div>
            <Row>
              <div style={pageTitle}>Comms for {securityIncidentId}</div>
              <CreateCommModal sec_id={securityIncidentId} comm_types={commTypes}/>
            </Row>
            <CommTable data={comms} comm_types={commTypes}/>
          </div>
      }
    </div>
  </AuthorizationWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Comms);