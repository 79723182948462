import React, { useState, useEffect } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import { Modal, Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {getCommTypeId} from '../SecurityIncidentHelpers';
import { useTranslation } from 'react-i18next';
import CreateCommModal from './CreateCommModal';
/* ========= PACKAGE IMPORTS ========= */

const schema = yup.object().shape({
  data: yup.string().required(),
  title: yup.string().required(),
  sec_id: yup.string().required(),
  sec_comm_id: yup.number().required(),
  filename: yup.string().required(),
});

const mapStateToProps = ({securityIncidentState, userState}) => {
  return {
    allSecIds : securityIncidentState.allSecIds,
    allCommIds: securityIncidentState.allCommIds,
    commTypes: securityIncidentState.commTypes,
    email: userState.user.email,
  }

};
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    requestCreateImports :  securityIncidentActions.requestCreateImports,
    requestComms : securityIncidentActions.requestComms,
    directlySetAllCommIds : securityIncidentActions.directlySetAllCommIds,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const CreateImportModal = ({requestCreateImports, requestComms, commTypes, email, directlySetAllCommIds, allSecIds, allCommIds}) => {
  const { t } = useTranslation(["translation"]);
  const [title, setTitle] = useState('');
  const [fileContents, setFileContents] = useState('');
  const [fileName, setFileName] = useState('');
  const [secId, setSecId] = useState('');
  const [commId, setCommId] = useState('');

  let styles = {
    error         :  {
      color       :  'red',
    },
    buttonError   :  {
      color       :  'red',
      marginLeft  :  '5px',
    },
    buttonRight   : {
      width       : '8rem',
      margin      : '1rem'
    },
    buttonLeft: {
      width       : '8rem',
      margin      : '1rem auto 1rem 1rem',
      float       : 'left'
    }
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const [showConfirmation,setShowConfirmation] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }
  const handleFileSelect = (event) => {
    // Do something with the selected file
    const data = event.target.files[0];
    if (data) {
      setFileName(data.name);
      const reader = new FileReader();

      reader.onload = (e) => {
        setFileContents(e.target.result);
      };
      reader.readAsText(data);
    }
  };

  const handleSecIdChange = (value) => {
    setSecId(value);
  };

  useEffect(() => {
    directlySetAllCommIds([]);
    requestComms(secId);
  }, [secId]);

  useEffect(() => {
    if (allSecIds.length > 0 && secId === ''){
      setSecId(allSecIds[0]);
    }
  }, [allSecIds]);

  useEffect(() => {
    if (allCommIds.length > 0){
      setCommId(allCommIds[0]);
    }
  }, [allCommIds]);

  const resetValues = () => {
    setTitle('');
    setFileContents('');
    setSecId('');
    setCommId('');
  }

  return (
    <div style={{'marginLeft': 'auto'}}>
      {/* Overlay Tooltip for button */}
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Creates a new Email Import</Tooltip>
        }
      >
        <Button  className="data-cy-create-email-import-modal-button" variant="primary" onClick={openModal}>Create Email Import</Button>
      </OverlayTrigger>
      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-create-email-import-modal">
      <Formik   
          enableReinitialize
          validationSchema={schema}
          onSubmit={(values, {resetForm}) => {
            requestCreateImports(values);
            setShowConfirmation(false);
            closeModal();
            resetForm();
            resetValues();
          }}
          initialValues={{
            data: fileContents,
            filename: fileName,
            sec_id: secId,
            sec_comm_id: commId,
            title: title,
            is_hidden: 'N',
            created_by: email,
          }}
        >
        {({
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => 
        (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create Email Import</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
              <Form.Group>
                <div>
                  <div style={styles.inputDiv}>
                    <Form.Label>Title</Form.Label>     
                    <Form.Control style={{height: 'unset'}} as="input" placeholder="title" value={title} onChange={e => setTitle(e.target.value)}> 
                    </Form.Control>
                    {errors.title && touched.title ? (
                      <div style={{color: 'red'}}>{errors.title}</div>
                    ) : null}
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <div>
                  <div style={styles.inputDiv}>
                    <Form.Label>Sec ID</Form.Label>     
                    <Form.Control style={{height: 'unset'}} as="select" placeholder="Sec ID" value={secId} onChange={e => handleSecIdChange(e.target.value)}> 
                      {allSecIds.map((id, i) => (
                        <option key={"secId" + i}>{id}</option>
                      ))}
                    </Form.Control>
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <div>
                  <div style={styles.inputDiv}>
                    <Form.Label style={{marginTop: '1rem'}}>Comm ID</Form.Label>
                    {secId && <div style={{float: 'right', marginBottom: '1rem'}}><CreateCommModal sec_id={secId} comm_types={commTypes} /></div>}
                    <Form.Control style={{height: 'unset'}} as="select" placeholder="Comm ID" value={commId} onChange={e => setCommId(e.target.value)}> 
                      {allCommIds.map((id, i) => (
                        <option key={"commId" + i}>{id}</option>
                      ))}
                    </Form.Control>
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <div>
                  <div style={styles.inputDiv}>
                    <Form.Label>CSV File</Form.Label> 
                    <br/>  
                    <input style={{marginBottom: '1rem'}} type="file" accept=".csv" onChange={handleFileSelect} />  
                    {errors.filename && touched.filename ? (
                      <div style={{color: 'red'}}>{errors.filename}</div>
                    ) : null}
                    <br/>
                    <Form.Control style={{height: 'unset'}} as="textarea" placeholder="csv contents" value={fileContents} onChange={e => setFileContents(e.target.value)}> 
                    </Form.Control>
                    {errors.data && touched.data ? (
                      <div style={{color: 'red'}}>{errors.data}</div>
                    ) : null}
                  </div>
                </div>
              </Form.Group>
              </div>
            </Modal.Body>
             
            
            <Modal.Footer>
              <div style={{display: 'contents'}}>
                <Button variant="secondary" onClick={closeModal} className="data-cy-create-import-modal-close" style={styles.buttonLeft}>Cancel</Button>
                <Button style={styles.buttonRight} className="data-cy-create-import-modal-ok" type="submit">Submit</Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
        </Formik>

      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateImportModal);