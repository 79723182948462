/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link }     from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Row } from 'react-bootstrap';
/* ========= REDUX IMPORTS ========= */
import { actions as securityIncidentActions } from '../../ducks/securityIncidents/securityIncident.index';
import SecurityIncidentTable from './Tables/SecurityIncidentTable'
import CreateSecurityIncidentModal from './Modals/CreateSecurityIncidentModal';

/* ========= COMPONENT IMPORTS ========= */
import Loading            from '../../components/loading';
import AuthorizationWrapper from '../../components/authorizationWrapper';


/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({ securityIncidentState }) => ({
    securityIncidents : securityIncidentState.securityIncidents,
    securityIncidentsLoading : securityIncidentState.securityIncidentsLoading,
});

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    requestSecurityIncidents  : securityIncidentActions.requestSecurityIncidents,
    requestCommTypes          : securityIncidentActions.requestCommTypes,
    requestComms              : securityIncidentActions.requestComms,
    requestEmails       : securityIncidentActions.requestEmails
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'textAlign'    :  'center',
    'fontSize'     :  '2rem',
  },
};

const requiredAction = 'view_security_incidents';
class SecurityIncidents extends Component {
  componentDidMount = () => {
    this.props.requestSecurityIncidents();
  }

  render = () => {
    const { topContainer, pageTitle } = styles;
    const { securityIncidentsLoading, securityIncidents } = this.props;
    return (
      <AuthorizationWrapper requiredAction={requiredAction}>
        <div style={topContainer}>
          {
            securityIncidentsLoading
            ? <div>
                <div style={pageTitle}>Loading...</div>
                <Loading height="75vh" />
              </div>
            : <div>
                <Row>
                  <Link style={{marginRight: 'auto'}} to="/manage_security_users" ><Button>Manage Emails</Button></Link>
                  <div style={pageTitle}>Security Incidents</div>
                  <CreateSecurityIncidentModal />
                </Row>
                <SecurityIncidentTable data={securityIncidents}/>
              </div>
          }

        </div>
      </AuthorizationWrapper>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SecurityIncidents);

