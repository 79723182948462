import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import { MdOutlineMode } from "react-icons/md";
import { Modal, Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
/* ========= PACKAGE IMPORTS ========= */
const schema = yup.object().shape({
  status: yup.string().required(),
  description: yup.string().required(),
  comm_type: yup.string().required(),
});

const mapStateToProps = () => ({
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    requestEditComm              :  securityIncidentActions.requestEditComm,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const UpdateCommModal = ({requestEditComm, data, comm_types}) => {
  const { t } = useTranslation(["translation"]);
  const [commType, setCommType] = useState(data.comm_type);
  const [status, setStatus] = useState(data.status);
  const [description, setDescription] = useState(data.description);

  let styles = {
    buttonRight   : {
      width       : '8rem',
      margin      : '1rem'
    },
    buttonLeft: {
      width       : '8rem',
      margin      : '1rem auto 1rem 1rem',
      float       : 'left'
    }
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const [showConfirmation,setShowConfirmation] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const submitForm = (fields) => {
    requestEditComm(fields);
    setShowConfirmation(false);
    closeModal();
  }

  return (
    <div>
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Edits this Security Incident</Tooltip>
        }
      >
        <MdOutlineMode onClick={openModal} style={{fontSize: "35px", color: '#007bff'}}/>
      </OverlayTrigger>
      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-edit-comm-modal">
      <Formik   
          enableReinitialize
          validationSchema={schema}
          onSubmit={values => submitForm(values)}
          initialValues={{
            sec_comm_id: data.sec_comm_id.toString(),
            description: description,
            status: status,
            comm_type: commType,
            is_hidden: data.is_hidden,
          }}
        >
        {({
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => 
        (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Security Incident</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Form.Group>
                  <div style={{display: 'flex'}}>
                    <div style={{'marginRight': 'auto', 'height': 'unset', 'width': '70%'}}>
                      <Form.Label>Comm Type</Form.Label>     
                      <Form.Control style={{height: 'unset'}} as="select" value={commType} onChange={e => setCommType(parseInt(e.target.value))}>
                        {comm_types.map(({id, display_name}) => {
                          return (
                            <option key={id} value={id.toString()}>{display_name}</option>
                          )
                          })
                        }
                      </Form.Control>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="textarea"
                    as="textarea"
                    className="data-cy-update-comm-modal-description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onBlur={handleBlur}
                    name="description"></Form.Control>
                    {errors.description && touched.description ? (
                      <div style={{color: 'red'}}>{errors.description}</div>
                    ) : null}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    type="textarea"
                    as="textarea"
                    className="data-cy-update-comm-modal-title"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    onBlur={handleBlur}
                    name="status"></Form.Control>
                    {errors.status && touched.status ? (
                      <div style={{color: 'red'}}>{errors.status}</div>
                    ) : null}
                </Form.Group>
              </div>
            </Modal.Body>
             
            
            <Modal.Footer>
              <div style={{display: 'contents'}}>
                <Button variant="secondary" onClick={closeModal} className="data-cy-update-comm-modal-close" style={styles.buttonLeft}>Cancel</Button>
                <Button style={styles.buttonRight} className="data-cy-update-comm-modal-ok" type="submit">Submit</Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
        </Formik>

      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(UpdateCommModal);