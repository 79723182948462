import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdDeleteForever } from "react-icons/md";
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import { Modal, Button, Tooltip, OverlayTrigger, Card } from 'react-bootstrap';
/* ========= PACKAGE IMPORTS ========= */

const mapStateToProps = () => ({
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    requestDeleteSecurityUsers    :  securityIncidentActions.requestDeleteSecurityUsers,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const DeleteSecurityUserModal = ({requestDeleteSecurityUsers, email, company_id, id}) => {
  const [modalIsOpen,setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const submitDelete = (id) => {
    requestDeleteSecurityUsers(id);
    closeModal();
  }

  return (
    <div>
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Deletes this Security User</Tooltip>
        }
      >
        <MdDeleteForever onClick={openModal} style={{fontSize: "35px", color: '#007bff'}}/>
      </OverlayTrigger>
      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-delete-security-user-modal">
        <Modal.Header closeButton>
          <Modal.Title>Delete Security User</Modal.Title>
        </Modal.Header>
        <Card>
          <Card.Body>
            <div>Confirm: Are you sure you want to delete this?</div>
            <br/>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>
              <div style={{fontWeight: 'bold'}}>Email:    </div>
              <div>{email}</div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{fontWeight: 'bold'}}>Company ID:    </div>
              <div>{company_id}</div>
            </div>
          </Card.Body>
        </Card>
        <div style={{display: 'flex'}}>
          <Button variant="secondary" onClick={closeModal} className="data-cy-delete-security-user-modal-close" style={{margin: '1vw', width: '8rem', marginRight: 'auto'}}>Cancel</Button>
          <Button variant="danger" onClick={() => submitDelete(id)} style={{float: 'right', width: '8rem', margin: '1vw'}}>Delete</Button>
        </div>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(DeleteSecurityUserModal);