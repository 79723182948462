import React,  {useState, Fragment}       from 'react';
import { useTable, useSortBy } from 'react-table'
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import ShowCsvData from '../Modals/ShowCsvData';
import UpdateStagedEmailModal from '../Modals/UpdateStagedEmailModal';
import {
  dateTimeToUTCString,
  sortOpi,
  sortDate,
} from '../../../utils/formatFunctions'
import DeleteModalTemplate from '../Modals/DeleteModalTemplate';
import { actions as securityIncidentActions } from '../../../ducks/securityIncidents/securityIncident.index';
import ModalTemplate from '../Modals/ModalTemplate';
import PreviewSendEmailModal from '../Modals/PreviewSendEmailModal';
const styles = {
  'tableContainer': {
    'fontSize'        :  '.94rem',
    'boxSizing'       :  'border-box',
    'borderRadius'    :  '4px',
    'backgroundColor' :  'rgb(250, 250, 250)',
    'boxShadow'       :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  'evenRow':{
    'backgroundColor': '#007bff2e'
  },
  'noData': {
    'textAlign': 'center',
  }
};

const mapStateToProps = () => ({
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    requestDeleteStagingEmails :  securityIncidentActions.requestDeleteStagingEmails,
    requestPromoteStagingEmails: securityIncidentActions.requestPromoteStagingEmails
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};



function RenderTable({ columns, values }) {
  const { t } = useTranslation(["translation"]);

  const data = React.useMemo(() => 
  values
  , [values]);

  const sorting = React.useMemo(
    () => [
      {
        id: "created_date",
        desc: true
      }
    ],
    []
  );
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : '';
  };

  const widthDict = {
    'sec_comm_id': 122,
    'comm_type': 260,
    'description': 300,
    'created_date': 230,
    'status': 230
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sorting,
      }
    },
    useSortBy,
  )
  return (
    <Fragment>
      <Table bordered {...getTableProps()} className="cy-history-table">  
        <thead>
          {headerGroups.map(headerGroup => (
            <tr className='rt-thead' {...headerGroup.getHeaderGroupProps()} >
              {headerGroup.headers.map((column, i) => {
                const {render, getHeaderProps} = column
                return (
                  <th style={{ width: column.size || widthDict[column.id] }} className={generateSortingIndicator(column) + " " + column.id + "-header"} key={'header' + i} {...getHeaderProps(column.getSortByToggleProps())}>{render("Header")}</th>
                )
              })}
            </tr>
          ))}
        </thead>
        {rows.length > 0 && <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {        
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr style={index % 2 == 0 ? styles.evenRow : {}}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>}
        {rows.length == 0 &&  <>
          <tbody>
            {
              [...Array(7)].map((e, i) => {
                if(i == 3){
                  return(<tr key={'row-' + i} style={styles.noData} className='rt-noData'><td colSpan={columns.length}>No Data</td></tr>)
                } 
                else{
                  return (<tr key={'row-' + i}>{columns.map((e, j) => (<td key={'col-' + j}/>))}</tr>)
                }
              })
            }
          </tbody>
        </>}
      </Table>
     
    </Fragment>
  );
}



const StagingEmailsTable = ({data, requestPromoteStagingEmails, requestDeleteStagingEmails}) => {
  const { headers, tableContainer } = styles;
  const { t } = useTranslation(["translation"]);
  const [rowData, setRowData] = useState(data);
  if (document.getElementsByClassName("-center")[0]){
    document.getElementsByClassName("-center")[0].style.display = 'none'
  }

  const columns = React.useMemo(
    () => [
        {
          Header: <b style={styles.test} data-cy='id-header'>ID</b>,
          headerStyle: headers,
          id: 'pkey',
          size: '122px',
          Cell          : ({value}) => <div>{value}</div>,
          accessor      : "pkey",
          sortMethod    : (a, b) => sortOpi(a, b),
        },
        {
          Header      : <b data-cy="internal-header">Comm Id</b>,
          headerStyle : headers,
          id          : 'sec_comm_id',
          size       : '50px',
          accessor    : data => <div style={{width: '50px'}}>{(data.sec_comm_id)}</div>,
        },
        {
          Header: <b data-cy='service-header'>Company Id</b>,
          headerStyle: headers,
          id: 'company_id',
          accessor    : data => <div style={{width: '100px'}}>{data.company_id}</div>,
          size: '260px',
        },
        {
          Header      : <b data-cy="internal-header">Subject</b>,
          headerStyle : headers,
          id          : 'subject',
          size       : '100px',
          accessor    : data => <div>{data.subject}</div>
        },
        {
          Header      : <b data-cy="internal-header">Valid</b>,
          headerStyle : headers,
          id          : 'is_valid',
          size       : '100px',
          accessor    : data => <div>{data.is_valid}</div>
        },
        {
          Header      : <b data-cy="internal-header">Promoted</b>,
          headerStyle : headers,
          id          : 'was_promoted',
          size       : '100px',
          accessor    : data => <div>{data.was_promoted ? 'Y' : <ModalTemplate 
            submitFunction={requestPromoteStagingEmails}
            type="Promote"
            id={{"ids": [data.pkey]}}/>
            }</div>
        },
        {
          Header: <b data-cy='data-center-header'>View</b>,
          headerStyle: headers,
          id: 'pkey5',
          width: 30,
          accessor: (data) => <PreviewSendEmailModal id={data.pkey} isButton={false} previewOnly={true} body={data.body} subject={data.subject}/>
        },
        {
          Header: <b data-cy='data-center-header'>Edit Import</b>,
          headerStyle: headers,
          id: 'pkey1',
          width: 50,
          accessor: (data) => <UpdateStagedEmailModal data={data} />
        },
        {
          Header: <b data-cy='data-center-header'>Delete</b>,
          headerStyle: headers,
          id: 'pkey4',
          width: 50,
          accessor: (data) => <DeleteModalTemplate 
          deleteFunction={requestDeleteStagingEmails}
          delete_id={data.pkey} 
          data={data}
          keys={["pkey", "sec_comm_id", "company_id", "subject", "is_valid", "was_promoted"]}
          displayKeys={["Staging Email ID", "Comm ID", "Company Id", "Subject", "Is Valid", "Was Promoted"]} 
          type="Staging Emails"
          html_modal={false} />
        },
      ]
    )

  return (
    <div style={tableContainer}>
      <RenderTable columns={columns} values={rowData} />
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(StagingEmailsTable);
