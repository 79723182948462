import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import { Modal, Button, Form, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
/* ========= PACKAGE IMPORTS ========= */
const mapStateToProps = ({securityIncidentState}) => ({
  emailContents       : securityIncidentState.emailContents,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setEmailContents                         : securityIncidentActions.setEmailContents,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const CreateComposeEmailModal = ({emailContents, setEmailContents, type}) => {
  const { t } = useTranslation(["translation"]);
  let styles = {
    buttonRight   : {
      width       : '8rem',
      margin      : '1rem'
    },
    buttonLeft: {
      width       : '8rem',
      margin      : '1rem auto 1rem 1rem',
      float       : 'left'
    }
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const [input, setInput] = useState('');
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableWidth, setTableWidth] = useState(2);
  const [tableHeight, setTableHeight] = useState(2);
  const [tableData, setTableData] = useState([["", ""], ["", ""]]);

  const isInt = (value) => {
      return !isNaN(value) && 
             parseInt(Number(value)) == value && 
             !isNaN(parseInt(value, 10));
  }

  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const resetTable = () => {
    setTableHeaders([]);
    setTableWidth(2);
    setTableHeight(2);
    setTableData([["", ""], ["", ""]]);
  }

  const onSubmit = () => {
    if (type === 'table'){
      let newEmailContents = [...emailContents];
      newEmailContents.push(<table key={"entity-table-" + newEmailContents.length} className="entity-table"><thead><tr>
      {tableHeaders.map((header, index) => {
        return (<th key={index}>{header}</th>)
      })}</tr></thead><tbody>
      {tableData.map((row, i) => {
        return (
        <tr>
        {row.map((data, j) => {
          return (<td key={i + '-' + j}>{data}</td>)
        })}
        </tr>
      )})}
      </tbody>
      </table>)
      setEmailContents(newEmailContents);
      resetTable();
      closeModal();
    }
    else{
      emailContents.push(<p className={type} key={type + '-' + emailContents.length}>{input}</p>);
      setEmailContents(emailContents);
      setInput('');
      closeModal();
    }
  }

  const changeTableDimensions = (height, width) => {
    if (height > tableHeight) {
      for (let i = tableHeight; i < height; i++) {
        var row = [];
        for (let j = 0; j < tableWidth; j++) {
          row.push('');
        }
        tableData.push(row);
      }
    }
    else if (height < tableHeight) {
      tableData.splice(height, tableHeight - height);
    }
    else{
      let newTableData = [];
      for (let i = 0; i < tableHeight; i++) {
        var row = [];
        for (let j = 0; j < tableWidth; j++) {
          row.push(tableData[i][j] ?? '');
        }
        newTableData.push(row);
      }
      setTableData(newTableData);
      return;
    }
    setTableData(tableData);
  }

  const changeTableWidth = (newWidth) => {
    if (isInt(newWidth) && newWidth > 0) {
      setTableWidth(parseInt(newWidth));
      changeTableDimensions(tableHeight, parseInt(newWidth));
    }
  }

  const changeTableHeight = (newHeight) => {
    if (isInt(newHeight) && newHeight > 0) {
      setTableHeight(parseInt(newHeight));
      changeTableDimensions(parseInt(newHeight), tableWidth);
    }
  }

  const changeTableData = (newData, i, j) => {
    const newTableData = [...tableData];
    newTableData[i][j] = newData;
    setTableData(newTableData);
  }

  const changeTableHeaders = (newData, i) => {
    const newHeaderData = [...tableHeaders];
    newHeaderData[i] = newData;
    setTableHeaders(newHeaderData);
  }


  return (
    <div style={{textAlign: 'center'}}>
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Creates new email line</Tooltip>
        }
      >
        <Button style={{marginRight: '1rem'}} onClick={openModal}>Add {type}</Button>
      </OverlayTrigger>
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-create-compose-email-modal">
        <Modal.Header>
          <Modal.Title>Create {type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {type != 'table' && <Form.Control as="textarea" value={input} onChange={e => setInput(e.target.value)}></Form.Control>}
            {type == 'table' && 
            <div>

              <Row style={{marginBottom: '3rem'}}>
                <Col>
                  <Form.Label>Table Width</Form.Label>
                  <Form.Control type="number" as="input" value={tableWidth} onChange={e => changeTableWidth(e.target.value)}></Form.Control>
                </Col>
                <Col>
                  <Form.Label>Table Height</Form.Label>
                  <Form.Control type="number" as="input" value={tableHeight} onChange={e => changeTableHeight(e.target.value)}></Form.Control>
                </Col>
              </Row>
              <Row>Headers</Row>
              <Row>
                {Array.from(Array(tableWidth)).map((_, i) => {
                  return (
                    <Col key={"headercol" + i} style={{marginBottom: '5px'}}>
                      <Form.Control key={'createheader-' + i} type="text" value={tableHeaders[i]} onChange={e => changeTableHeaders(e.target.value, i)}></Form.Control>
                    </Col>
                  )
                })}
              </Row>
              <Row>Data</Row>
              {Array.from(Array(tableHeight)).map((_, i) => {
                return(
                  <Row key={"datarow" + i}>
                    {Array.from(Array(tableWidth)).map((_, j) => {
                      return (
                        <Col key={"datacol" + i} style={{marginBottom: '5px'}}>
                          <Form.Control key={"createdata-" + i + "-" + j} placeholder="data" value={tableData[i][j]} onChange={e => changeTableData(e.target.value, i, j)}></Form.Control>
                        </Col>
                      )
                    })
                  }
                  </Row>
                )})
              }
            </div>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div style={{display: 'contents'}}>
            <Button variant="secondary" onClick={closeModal} className="data-cy-create-compose-email-modal-close" style={styles.buttonLeft}>Cancel</Button>
            <Button style={styles.buttonRight} className="data-cy-create-compose-email-modal-ok" onClick={e => onSubmit(type)}>Submit</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateComposeEmailModal);