import http from "../../services/httpService";

let apiEndpoint = '/sec';

export const getCommTypes = async () => await http.get(`${apiEndpoint}/comm_types/`);

export const getSecurityIncidents = async ()          => await http.get(`${apiEndpoint}/incidents/`);
export const createSecurityIncident = async (payload) => await http.post(`${apiEndpoint}/incidents/`, payload);
export const updateSecurityIncident = async (payload) => await http.put(`${apiEndpoint}/incidents/`, payload);
export const deleteSecurityIncident = async (id)      => await http.get(`${apiEndpoint}/incidents/delete/?sec_id=${id}`);

export const getCommsId   = async (sec_id)  => await http.get(`${apiEndpoint}/comms/?sec_id=${sec_id}`);
export const getComms   = async ()  => await http.get(`${apiEndpoint}/comms/`);
export const createComm = async (payload) => await http.post(`${apiEndpoint}/comms/`, payload);
export const updateComm = async (payload) => await http.put(`${apiEndpoint}/comms/`, payload);
export const deleteComm = async (id)      => await http.get(`${apiEndpoint}/comms/delete/?sec_comm_id=${id}`);

export const getEmails          = async (sec_comm_id)     => await http.get(`${apiEndpoint}/emails/?sec_comm_id=${sec_comm_id}`);
export const getSpecificEmails  = async (comm_content_id) => await http.get(`${apiEndpoint}/emails/?comm_content_id=${comm_content_id}`);
export const createEmails       = async (payload)         => await http.post(`${apiEndpoint}/emails/`, payload);
export const updateEmails       = async (payload)         => await http.put(`${apiEndpoint}/emails/`, payload);
export const deleteEmails       = async (comm_content_id) => await http.get(`${apiEndpoint}/emails/delete/?comm_content_id=${comm_content_id}`);

export const getSecurityUsers    = async ()        => await http.get(`${apiEndpoint}/user`);
export const createSecurityUsers = async (payload) => await http.post(`${apiEndpoint}/user/`, payload);
export const updateSecurityUsers = async (payload) => await http.put(`${apiEndpoint}/user/`, payload);
export const deleteSecurityUsers = async (user_id) => await http.get(`${apiEndpoint}/user/delete/?user_id=${user_id}`);

export const getImports    = async ()        => await http.get(`${apiEndpoint}/imports/`);
export const createImports = async (payload) => await http.post(`${apiEndpoint}/imports/`, payload);
export const updateImports = async (payload) => await http.put(`${apiEndpoint}/imports/`, payload);
export const deleteImports = async (id)      => await http.get(`${apiEndpoint}/imports/delete/?import_id=${id}`);

export const getStagingEmails    = async ()        => await http.get(`${apiEndpoint}/staging/`);
export const createStagingEmails = async (payload) => await http.post(`${apiEndpoint}/staging/`, payload);
export const updateStagingEmails = async (payload) => await http.put(`${apiEndpoint}/staging/`, payload);
export const promoteStagingEmails = async (payload)      => await http.put(`${apiEndpoint}/staging/promote/`, payload);
export const deleteStagingEmails = async (id)      => await http.get(`${apiEndpoint}/staging/delete/?staging_id=${id}`);

export const sendEmails = async (payload) => await http.post(`${apiEndpoint}/send_emails/`, payload);
