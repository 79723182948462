import React, { useState, useEffect } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaMedal } from "react-icons/fa";
import { Modal, Button, Tooltip, OverlayTrigger, Card, Row, Col } from 'react-bootstrap';
/* ========= PACKAGE IMPORTS ========= */

const mapStateToProps = () => ({

});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const ModalTemplate = ({submitFunction, type, display=null, id=null}) => {
  const [modalIsOpen,setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }
  const submitFunc = (data) => {
    submitFunction(data);
  }
  return (
    <div>
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>{type} this</Tooltip>
        }
      >
        <FaMedal onClick={openModal} style={{fontSize: "35px", color: '#007bff'}}/>
      </OverlayTrigger>
      
      <Modal size='lg' show={modalIsOpen} onHide={closeModal} className="data-cy-delete-security-incident-modal">
        <Modal.Header closeButton>
          <Modal.Title>{type}</Modal.Title>
        </Modal.Header>
        <Card style={{textAlign: 'center'}}>
          <Card.Body>
            <div>Confirm: Are you sure you want to {type} this?</div>
            <br/>
            <div>{display}</div>
          </Card.Body>
        </Card>
        <div style={{display: 'flex'}}>
          <Button variant="secondary" onClick={closeModal} className="data-cy-delete-security-incident-modal-close" style={{margin: '1vw', width: '8rem', marginRight: 'auto'}}>Cancel</Button>
          <Button variant="primary" onClick={() => submitFunc(id)} style={{float: 'right', width: '8rem', margin: '1vw'}}>{type}</Button>
        </div>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(ModalTemplate);