import React,  {useState}       from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { Table, Card, InputGroup, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  dateTimeToUTCString,
  sortOpi,
  sortDate,
} from '../../../utils/formatFunctions'
import UpdateSecurityUserModal from '../Modals/UpdateSecurityUserModal';
import DeleteSecurityUserModal from '../Modals/DeleteSecurityUserModal';

const styles = {
  'evenRow':{
    'backgroundColor': '#007bff2e'
  },
  'tableContainer': {
    'fontSize'        :  '.94rem',
    'boxSizing'       :  'border-box',
    'borderRadius'    :  '4px',
    'backgroundColor' :  'rgb(250, 250, 250)',
    'boxShadow'       :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  'noData': {
    'textAlign': 'center',
  }
};

function RenderTable({ columns, values }) {
  const { t } = useTranslation(["translation"]);
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const data = React.useMemo(() => 
  values
  , [values]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter // useGlobalFilter!
  )

   // Define a default UI for filtering
   function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    }){
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
      document.getElementById("search-bar").focus()
    }, 800)

    return (
      <span>
        <InputGroup>
          <InputGroup.Prepend style={{margin: 'auto .5rem'}}>Filter{' '}</InputGroup.Prepend>
          <FormControl id="search-bar" value={value || ""}
            onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`${count} records...`}
            style={{
              fontSize: '1.1rem',
              borderTop: '0',
              borderBottom: '0'
            }} />
        </InputGroup>
      </span>
    )
  }

  return (
    <>
    <Card>
       <GlobalFilter
         preGlobalFilteredRows={preGlobalFilteredRows}
         globalFilter={state.globalFilter}
         setGlobalFilter={setGlobalFilter}
       />
     </Card>
     <Table bordered {...getTableProps()}>
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => {
               const {render, getHeaderProps} = column
               return (
                 <th {...getHeaderProps()}>{render("Header")}</th>
               )
             })}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()}>
         {rows.map((row, i) => {
           prepareRow(row);
           return (

             <tr {...row.getRowProps()} >
               {row.cells.map(cell => {
                 return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
               })}
             </tr>
           );
         })}
       </tbody>
     </Table>
   </>
  );
}

const ManageSecurityUsersTable = ({data, listSize}) => {
  const { headers, tableContainer } = styles;
  const { t } = useTranslation(["translation"]);
  const [rowData, setRowData] = useState(data);
  if (document.getElementsByClassName("-center")[0]){
    document.getElementsByClassName("-center")[0].style.display = 'none'
  }

  const columns = React.useMemo(
    () => [
        {
          Header: <b style={styles.test} data-cy='id-header'>ID</b>,
          headerStyle: headers,
          id: 'id',
          size: '122px',
          Cell          : ({value}) => <div>{value}</div>,
          accessor      : "id",
          sortMethod    : (a, b) => sortOpi(a, b),
          filter: 'includes'
        },
        {
          Header      : <b data-cy="internal-header">Company ID</b>,
          headerStyle : headers,
          id          : 'company_id',
          size       : '100px',
          accessor    : data => <div style={{width: '70px'}}>{data.company_id}</div>,
          filter: 'includes'
        },
        {
          Header: <b data-cy='service-header'>Email</b>,
          headerStyle: headers,
          id: 'email',
          accessor: (data) => data.email,
          size: '300px',
          filter: 'includes'
        },
        {
          Header: <b data-cy="start-date-header">Created Date</b> ,
          headerStyle: headers,
          id: 'created_date',
          accessor: (data) => dateTimeToUTCString(data.created_date),
          size: '230px',
          sortMethod    :  (a, b) => sortDate(a, b),
          filter: 'includes'
        },
        {
          Header: <b data-cy="start-date-header">Modified Date</b> ,
          headerStyle: headers,
          id: 'modified_date',
          accessor: (data) => data.modified_date != "None" ? dateTimeToUTCString(data.modified_date) : "",
          size: '230px',
          sortMethod    :  (a, b) => sortDate(a, b),
          filter: 'includes'
        },
        {
          Header: <b data-cy='data-center-header'>Edit</b>,
          headerStyle: headers,
          id: 'sec_comm_id2',
          width: 50,
          accessor: (data) => <UpdateSecurityUserModal data={data} />
        },
        {
          Header: <b data-cy='data-center-header'>Delete</b>,
          headerStyle: headers,
          id: 'sec_comm_id3',
          width: 50,
          accessor: (data) => <DeleteSecurityUserModal email={data.email} company_id={data.company_id} id={data.id} />
        },
      ]
    )

  return (
    <div style={tableContainer}>
      <RenderTable columns={columns} values={rowData} />
    </div>
  );
}

export default ManageSecurityUsersTable;
