import React,  {useState, Fragment}       from 'react';
import { Link }     from 'react-router-dom';
import { useTable, useSortBy } from 'react-table'
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table } from 'react-bootstrap';
import { MdExpandMore } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import {
  dateTimeToUTCString,
  sortOpi,
  sortDate,
} from '../../../utils/formatFunctions'
import UpdateSecurityIncidentModal from '../Modals/UpdateSecurityIncidentModal';
import DeleteModalTemplate from '../Modals/DeleteModalTemplate';

const mapStateToProps = () => ({
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    requestDeleteSecurityIncident :  securityIncidentActions.requestDeleteSecurityIncident,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  'evenRow':{
    'backgroundColor': '#007bff2e'
  },
  'tableContainer': {
    'fontSize'        :  '.94rem',
    'boxSizing'       :  'border-box',
    'borderRadius'    :  '4px',
    'backgroundColor' :  'rgb(250, 250, 250)',
    'boxShadow'       :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  'noData': {
    'textAlign': 'center',
  }
};

function RenderTable({ columns, values }) {
  const { t } = useTranslation(["translation"]);

  const data = React.useMemo(() => 
  values
  , [values]);

  const sorting = React.useMemo(
    () => [
      {
        id: "start_date",
        desc: true
      }
    ],
    []
  );
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : '';
  };

  const widthDict = {
    'id': 122,
    'title': 260,
    'description': 300,
    'start_date': 230,
    'actions': 230
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sorting,
      }
    },
    useSortBy,
  )
  return (
    <Fragment>
      <Table bordered {...getTableProps()} className="cy-history-table">  
        <thead>
          {headerGroups.map(headerGroup => (
            <tr className='rt-thead' {...headerGroup.getHeaderGroupProps()} >
              {headerGroup.headers.map((column, i) => {
                const {render, getHeaderProps} = column
                return (
                  <th style={{ width: column.size || widthDict[column.id] }} className={generateSortingIndicator(column) + " " + column.id + "-header"} key={'header' + i} {...getHeaderProps(column.getSortByToggleProps())}>{render("Header")}</th>
                )
              })}
            </tr>
          ))}
        </thead>
        {rows.length > 0 && <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {        
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr style={index % 2 == 0 ? styles.evenRow : {}}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>}
        {rows.length == 0 &&  <>
          <tbody>
            {
              [...Array(7)].map((e, i) => {
                if(i == 3){
                  return(<tr key={'row-' + i} style={styles.noData} className='rt-noData'><td colSpan={columns.length}>No Data</td></tr>)
                } 
                else{
                  return (<tr key={'row-' + i}>{columns.map((e, j) => (<td key={'col-' + j}/>))}</tr>)
                }
              })
            }
          </tbody>
        </>}
      </Table>
     
    </Fragment>
  );
}

const SecurityIncidentTable = ({data, listSize, requestDeleteSecurityIncident}) => {
  const { headers, tableContainer } = styles;
  const { t } = useTranslation(["translation"]);
  const [rowData, setRowData] = useState(data);
  if (document.getElementsByClassName("-center")[0]){
    document.getElementsByClassName("-center")[0].style.display = 'none'
  }

  function sortEndDate(rowA, rowB, id, desc) {
    if (!rowA.values[id]) return 1;
    if (!rowB.values[id]) return -1;
    if (rowA.values[id] > rowB.values[id]) return 1; 
    if (rowB.values[id] > rowA.values[id]) return -1;
    return 0;
  }

  const columns = React.useMemo(
    () => [
        {
          Header: <b style={styles.test} data-cy='id-header'>Incident ID</b>,
          headerStyle: headers,
          id: 'sec_id',
          size: '122px',
          Cell          : ({value}) => <div>{value}</div>,
          accessor      : "sec_id",
          sortMethod    : (a, b) => sortOpi(a, b),
        },
        {
          Header      : <b data-cy="internal-header">Title</b>,
          headerStyle : headers,
          id          : 'title',
          size       : '100px',
          accessor    : data => <div style={{width: '100px'}}>{data.title}</div>,
        },
        {
          Header: <b data-cy='service-header'>Status</b>,
          headerStyle: headers,
          id: 'status',
          accessor    : data => <div style={{width: '100px'}}>{data.status}</div>,
          size: '260px',
        },
        {
          Header: <b data-cy="start-date-header">{t('containers.incidentManagementTable.start')}</b> ,
          headerStyle: headers,
          id: 'start_date',
          accessor: 'start_date',
          size: '230px',
          Cell      : ({value}) => (<div style={{width: '230px'}}>{dateTimeToUTCString(value)}</div>),
          sortMethod    :  (a, b) => sortDate(a, b),
        },
        {
          Header: <b data-cy="start-date-header">{t('containers.incidentManagementTable.end')}</b> ,
          headerStyle: headers,
          id: 'end_date',
          accessor: 'end_date',
          size: '230px',
          Cell      : ({value}) => (<div style={{width: '230px'}}>{dateTimeToUTCString(value)}</div>),
          sortMethod    :  (a, b) => sortDate(a, b),
        },
        {
          Header      : <b data-cy="internal-header">Description</b>,
          headerStyle : headers,
          id          : 'description',
          size       : '100px',
          accessor    : data => <div style={{width: '100px'}}>{data.description}</div>,
        },

        {
          Header: <b data-cy='data-center-header'>Manage Comms</b>,
          headerStyle: headers,
          id: 'sec_id1',
          width: 50,
          accessor: (data) => <Link to={`/comms/${data.sec_id}`} style={{marginLeft: '10px'}} ><MdExpandMore style={{fontSize: "35px"}}/></Link>
        },
        {
          Header: <b data-cy='data-center-header'>Edit</b>,
          headerStyle: headers,
          id: 'sec_id2',
          width: 50,
          accessor: (data) => <UpdateSecurityIncidentModal data={data} />
        },
        {
          Header: <b data-cy='data-center-header'>Delete</b>,
          headerStyle: headers,
          id: 'sec_id3',
          width: 50,
          accessor: (data) => <DeleteModalTemplate 
          delete_id={data.sec_id} 
          data={data} 
          deleteFunction={requestDeleteSecurityIncident} 
          type="Security Incident"
          displayKeys={["Incident ID", "Title", "Status", "Start Date", "Description"]}
          keys={["sec_id", "title", "status", "start_date", "description"]}/>
        },
      ]
    )
  return (
    <div style={tableContainer}>
      <RenderTable columns={columns} values={rowData} />
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(SecurityIncidentTable);
