
import { func } from 'prop-types';

/* ===== STATE_DEFINITION ===== */
const initialState = {
  securityIncidents         : [],
  commTypes                 : [],
  comms                     : [],  
  allCommIds                : [],
  allSecIds                 : [],
  emails              : [],
  specificEmails      : {},
  emailContents             : [],
  securityUsers             : [],
  stagingEmails             : [],
  imports                   : [],
  securityIncidentsLoading  : true,
  commsLoading              : true,
  commTypesLoading          : true,
  emailsLoading       : true,
  specificEmailsLoading: true,
  emailPreviewLoading       : false,
  securityUsersLoading      : true,
  importsLoading            : true,
  stagingEmailsLoading      : true,
  error                     : false,
};

export const securityIncidentProptypes = {
  requestSecurityIncidents    :  func,
  requestCommTypes            :  func,
  requestComms                :  func,
  requestEmails               :  func,
  requestSpecificEmails       :  func,
  requestSecurityUsers        :  func,
  requestStagingEmails        : func,
  requestImports              : func,

  requestCreateSecurityIncident :  func,
  requestEditSecurityIncident   :  func,
  requestDeleteSecurityIncident : func,

  requestCreateComm             : func,
  requestEditComm               : func,
  requestDeleteComm             : func,

  requestCreateEmails     : func,
  requestEditEmails       : func, 
  requestDeleteEmails     : func, 

  requestCreateSecurityUsers     : func,
  requestEditSecurityUsers       : func, 
  requestDeleteSecurityUsers     : func, 

  requestCreateImports          : func,
  requestEditImports            : func,
  requestDeleteImports          : func,

  requestCreateStagingEmails    : func,
  requestEditStagingEmails      : func,
  requestPromoteStagingEmails   : func,
  requestDeleteStagingEmails    : func,

  setSecurityIncidents        :  func,
  setCommTypes                :  func,
  setComms                    :  func,
  setEmails                   :  func,
  setSpecificEmails           :  func,
  setEmailContents            :  func,
  setImports                  : func,
  setStagingEmails            : func,
  setAllCommIds               : func,
  setAllSecIds                : func,
  directlySetAllCommIds       : func,

  setSecurityIncidentsLoading :  func,
  setCommsLoading             :  func,
  setCommTypesLoading         :  func,
  setEmailsLoading            :  func,
  setEmailPreviewLoading      :  func,
  setSpecificEmailsLoading    : func,
  setSecurityUsersLoading     : func,
  setImportsLoading           : func,
  setStagingEmailsLoading     : func,

  updateOneSecurityIncident   :  func,  
  updateDeleteSecurityIncident:  func,
  updateOneComm               :  func,
  updateDeleteComm            :  func,  
  updateOneCommContent        :  func,
  updateDeleteCommContent     :  func,
  updateDeleteImport          :  func,
  updateDeleteSecurityUser    :  func,
  updateDeleteStagingEmail    :  func,
  updateAddSecurityUser       :  func,
  updateOneSecurityUser       :  func,
  updateOneStagingEmail       :  func,
  updatePromoteStagingEmail   :  func,
  updateAddImport             :  func,

  requestSendEmails           : func,
};


/* ===== TYPES ===== */
export const types = {
  REQUEST_SECURITY_INCIDENTS      : '[securityIncidents] REQUEST_SECURITY_INCIDENTS',
  REQUEST_COMM_TYPES              : '[securityIncidents] REQUEST_COMM_TYPES',
  REQUEST_COMMS                   : '[securityIncidents] REQUEST_COMMS',
  REQUEST_EMAILS           : '[securityIncidents] REQUEST_EMAILS',
  REQUEST_SPECIFIC_EMAILS  : '[securityIncidents] REQUEST_SPECIFIC_EMAILS',
  REQUEST_SECURITY_USERS          : '[securityIncidents] REQUEST_SECURITY_USERS',
  REQUEST_IMPORTS                 : '[securityIncidents] REQUEST_IMPORTS',
  REQUEST_STAGING_EMAILS          : '[securityIncidents] REQUEST_STAGING_EMAILS',

  REQUEST_CREATE_SECURITY_INCIDENT  : '[securityIncidents] REQUEST_CREATE_SECURITY_INCIDENT',
  REQUEST_EDIT_SECURITY_INCIDENT    : '[securityIncidents] REQUEST_EDIT_SECURITY_INCIDENT',
  REQUEST_DELETE_SECURITY_INCIDENT  : '[securityIncidents] REQUEST_DELETE_SECURITY_INCIDENT',

  REQUEST_CREATE_COMM               : '[securityIncidents] REQUEST_CREATE_COMM',
  REQUEST_EDIT_COMM                 : '[securityIncidents] REQUEST_EDIT_COMM',
  REQUEST_DELETE_COMM               : '[securityIncidents] REQUEST_DELETE_COMM',

  REQUEST_CREATE_EMAILS       : '[securityIncidents] REQUEST_CREATE_EMAILS',
  REQUEST_EDIT_EMAILS         : '[securityIncidents] REQUEST_EDIT_EMAILS',
  REQUEST_DELETE_EMAILS       : '[securityIncidents] REQUEST_DELETE_EMAILS',

  REQUEST_CREATE_SECURITY_USERS       : '[securityIncidents] REQUEST_CREATE_SECURITY_USERS',
  REQUEST_EDIT_SECURITY_USERS         : '[securityIncidents] REQUEST_EDIT_SECURITY_USERS',
  REQUEST_DELETE_SECURITY_USERS       : '[securityIncidents] REQUEST_DELETE_SECURITY_USERS',

  REQUEST_CREATE_IMPORTS          : '[securityIncidents] REQUEST_CREATE_IMPORTS',
  REQUEST_EDIT_IMPORTS            : '[securityIncidents] REQUEST_EDIT_IMPORTS',
  REQUEST_DELETE_IMPORTS          : '[securityIncidents] REQUEST_DELETE_IMPORTS',

  REQUEST_CREATE_STAGING_EMAILS    : '[securityIncidents] REQUEST_CREATE_STAGING_EMAILS',
  REQUEST_EDIT_STAGING_EMAILS      : '[securityIncidents] REQUEST_EDIT_STAGING_EMAILS',
  REQUEST_PROMOTE_STAGING_EMAILS   : '[securityIncidents] REQUEST_PROMOTE_STAGING_EMAILS',
  REQUEST_DELETE_STAGING_EMAILS    : '[securityIncidents] REQUEST_DELETE_STAGING_EMAILS',

  SET_SECURITY_INCIDENTS          : '[securityIncidents] SET_SECURITY_INCIDENTS',
  SET_SECURITY_USERS              : '[securityIncidents] SET_SECURITY_USERS',
  SET_COMM_TYPES                  : '[securityIncidents] SET_COMM_TYPES',
  SET_COMMS                       : '[securityIncidents] SET_COMMS',
  SET_EMAILS                      : '[securityIncidents] SET_EMAILS',
  SET_SPECIFIC_EMAILS             : '[securityIncidents] SET_SPECIFIC_EMAILS',
  SET_EMAIL_CONTENTS              : '[securityIncidents] SET_EMAIL_CONTENTS',
  SET_IMPORTS                     : '[securityIncidents] SET_IMPORTS',
  SET_STAGING_EMAILS              : '[securityIncidents] SET_STAGING_EMAILS',
  SET_ALL_COMM_IDS                : '[securityIncidents] SET_ALL_COMM_IDS',
  SET_ALL_SEC_IDS                 : '[securityIncidents] SET_ALL_SEC_IDS',
  DIRECTLY_SET_ALL_COMM_IDS       : '[securityIncidents] DIRECTLY_SET_ALL_COMM_IDS',
  
  SET_SECURITY_INCIDENTS_LOADING  : '[securityIncidents] SET_SECURITY_INCIDENTS_LOADING',
  SET_COMMS_LOADING               : '[securityIncidents] SET_COMMS_LOADING',
  SET_COMM_TYPES_LOADING          : '[securityIncidents] SET_COMM_TYPES_LOADING',
  SET_EMAILS_LOADING              : '[securityIncidents] SET_EMAILS_LOADING',
  SET_EMAIL_PREVIEW_LOADING       : '[securityIncidents] SET_EMAIL_PREVIEW_LOADING',
  SET_SPECIFIC_EMAILS_LOADING     : '[securityIncidents] SET_SPECIFIC_EMAILS_LOADING',
  SET_SECURITY_USERS_LOADING      : '[securityIncidents] SET_SECURITY_USERS_LOADING',
  SET_IMPORTS_LOADING             : '[securityIncidents] SET_IMPORTS_LOADING',
  SET_STAGING_EMAILS_LOADING      : '[securityIncidents] SET_STAGING_EMAILS_LOADING',


  UPDATE_ONE_SECURITY_INCIDENT    : '[securityIncidents] UPDATE_ONE_SECURITY_INCIDENT',
  UPDATE_DELETE_SECURITY_INCIDENT : '[securityIncidents] UPDATE_DELETE_SECURITY_INCIDENT',
  UPDATE_ONE_COMM                 : '[securityIncidents] UPDATE_ONE_COMM',
  UPDATE_DELETE_COMM              : '[securityIncidents] UPDATE_DELETE_COMM',
  UPDATE_ONE_COMM_CONTENT         : '[securityIncidents] UPDATE_ONE_COMM_CONTENT',
  UPDATE_DELETE_COMM_CONTENT      : '[securityIncidents] UPDATE_DELETE_COMM_CONTENT',
  UPDATE_DELETE_IMPORT            : '[securityIncidents] UPDATE_DELETE_IMPORT',
  UPDATE_DELETE_SECURITY_USER     : '[securityIncidents] UPDATE_DELETE_SECURITY_USER',
  UPDATE_DELETE_STAGING_EMAIL    : '[securityIncidents] UPDATE_DELETE_STAGING_EMAIL',
  UPDATE_ADD_SECURITY_USER        : '[securityIncidents] UPDATE_ADD_SECURITY_USER',
  UPDATE_ONE_SECURITY_USER        : '[securityIncidents] UPDATE_ONE_SECURITY_USER',
  UPDATE_ONE_STAGING_EMAIL        : '[securityIncidents] UPDATE_ONE_STAGING_EMAIL',
  UPDATE_PROMOTE_STAGING_EMAIL    : '[securityIncidents] UPDATE_PROMOTE_STAGING_EMAIL',
  UPDATE_ADD_IMPORT               : '[securityIncidents] UPDATE_ADD_IMPORT',
 
  REQUEST_SEND_EMAILS             : '[securityIncidents] REQUEST_SEND_EMAILS',
}


/* ===== ACTION_CREATORS ===== */
export const actions = {
  requestSecurityIncidents    : ()            => ({type: types.REQUEST_SECURITY_INCIDENTS}),
  requestCommTypes            : ()            => ({type: types.REQUEST_COMM_TYPES}),
  requestComms                : (sec_id)      => ({type: types.REQUEST_COMMS, payload: sec_id}),
  requestEmails         : (sec_comm_id) => ({type: types.REQUEST_EMAILS, payload: sec_comm_id}),
  requestSpecificEmails : (emails_id) => ({type: types.REQUEST_SPECIFIC_EMAILS, payload: emails_id}),
  requestSecurityUsers        : ()            => ({type: types.REQUEST_SECURITY_USERS}),
  requestImports              : ()            => ({type: types.REQUEST_IMPORTS}),
  requestStagingEmails        : (import_id)            => ({type: types.REQUEST_STAGING_EMAILS, payload: import_id}),

  requestCreateSecurityIncident : (payload) => ({type: types.REQUEST_CREATE_SECURITY_INCIDENT, payload}),
  requestEditSecurityIncident   : (payload) => ({type: types.REQUEST_EDIT_SECURITY_INCIDENT, payload}),
  requestDeleteSecurityIncident : (sec_id) => ({type: types.REQUEST_DELETE_SECURITY_INCIDENT, sec_id}),

  requestCreateComm             : (payload) => ({type: types.REQUEST_CREATE_COMM, payload}),
  requestEditComm               : (payload) => ({type: types.REQUEST_EDIT_COMM, payload}),
  requestDeleteComm             : (sec_comm_id) => ({type: types.REQUEST_DELETE_COMM, sec_comm_id}),

  requestCreateEmails     : (payload) => ({type: types.REQUEST_CREATE_EMAILS, payload}),
  requestEditEmails       : (payload) => ({type: types.REQUEST_EDIT_EMAILS, payload}),
  requestDeleteEmails     : (comm_content_id) => ({type: types.REQUEST_DELETE_EMAILS, comm_content_id}),

  requestCreateSecurityUsers     : (payload) => ({type: types.REQUEST_CREATE_SECURITY_USERS, payload}),
  requestEditSecurityUsers       : (payload) => ({type: types.REQUEST_EDIT_SECURITY_USERS, payload}),
  requestDeleteSecurityUsers     : (user_id) => ({type: types.REQUEST_DELETE_SECURITY_USERS, user_id}),

  requestCreateImports          : (payload) => ({type: types.REQUEST_CREATE_IMPORTS, payload}),
  requestEditImports            : (payload) => ({type: types.REQUEST_EDIT_IMPORTS, payload}),
  requestDeleteImports          : (imports_id) => ({type: types.REQUEST_DELETE_IMPORTS, imports_id}),

  requestCreateStagingEmails    : (payload) => ({type: types.REQUEST_CREATE_STAGING_EMAILS, payload}),
  requestEditStagingEmails      : (payload) => ({type: types.REQUEST_EDIT_STAGING_EMAILS, payload}),
  requestPromoteStagingEmails   : (ids)     => ({type: types.REQUEST_PROMOTE_STAGING_EMAILS, ids}),
  requestDeleteStagingEmails    : (staging_email_id) => ({type: types.REQUEST_DELETE_STAGING_EMAILS, staging_email_id}),

  setSecurityIncidents        : (incidents)       => ({type: types.SET_SECURITY_INCIDENTS, payload: incidents}),
  setSecurityUsers            : (users)           => ({type: types.SET_SECURITY_USERS, payload: users}),
  setCommTypes                : (commTypes)       => ({type: types.SET_COMM_TYPES, payload: commTypes}),
  setComms                    : (comms)           => ({type: types.SET_COMMS, payload: comms}),
  setEmails                   : (commContent)     => ({type: types.SET_EMAILS, payload: commContent}),
  setSpecificEmails           : (commContent)     => ({type: types.SET_SPECIFIC_EMAILS, payload: commContent}),
  setEmailContents            : (emailContents)   => ({type: types.SET_EMAIL_CONTENTS, payload: emailContents}),
  setImports                  : (imports)         => ({type: types.SET_IMPORTS, payload: imports}),
  setStagingEmails            : (stagingEmails)   => ({type: types.SET_STAGING_EMAILS, payload: stagingEmails}),
  setAllCommIds               : ()           => ({type: types.SET_ALL_COMM_IDS}),
  setAllSecIds                : ()          => ({type: types.SET_ALL_SEC_IDS}),
  directlySetAllCommIds       : (commIds)    => ({type: types.DIRECTLY_SET_ALL_COMM_IDS, payload: commIds}),

  setSecurityIncidentsLoading     : (loading)      => ({type: types.SET_SECURITY_INCIDENTS_LOADING, payload: loading}),
  setCommsLoading                 : (loading)      => ({type: types.SET_COMMS_LOADING, payload: loading}),
  setCommTypesLoading             : (loading)      => ({type: types.SET_COMM_TYPES_LOADING, payload: loading}),
  setEmailsLoading          : (loading)      => ({type: types.SET_EMAILS_LOADING, payload: loading}),
  setEmailPreviewLoading          : (loading)      => ({type: types.SET_EMAIL_PREVIEW_LOADING, payload: loading}),
  setSpecificEmailsLoading  : (loading)      => ({type: types.SET_SPECIFIC_EMAILS_LOADING, payload: loading}),
  setSecurityUsersLoading         : (loading)      => ({type: types.SET_SECURITY_USERS_LOADING, payload: loading}),
  setImportsLoading               : (loading)      => ({type: types.SET_IMPORTS_LOADING, payload: loading}),
  setStagingEmailsLoading         : (loading)      => ({type: types.SET_STAGING_EMAILS_LOADING, payload: loading}),

  updateOneSecurityIncident   : (incident)        => ({type: types.UPDATE_ONE_SECURITY_INCIDENT, payload: incident}),
  updateDeleteSecurityIncident: (sec_id)          => ({type: types.UPDATE_DELETE_SECURITY_INCIDENT, sec_id}),
  updateOneComm               : (comm)            => ({type: types.UPDATE_ONE_COMM, payload: comm}),
  updateDeleteComm            : (sec_comm_id)     => ({type: types.UPDATE_DELETE_COMM, sec_comm_id}),
  updateOneCommContent        : (commContent)     => ({type: types.UPDATE_ONE_COMM_CONTENT, payload: commContent}),
  updateDeleteCommContent     : (comm_content_id) => ({type: types.UPDATE_DELETE_COMM_CONTENT, comm_content_id}),
  updateDeleteImport          : (imports_id)      => ({type: types.UPDATE_DELETE_IMPORT, imports_id}),
  updateDeleteSecurityUser    : (user_id)         => ({type: types.UPDATE_DELETE_SECURITY_USER, user_id}),
  updateDeleteStagingEmail    : (staging_email_id)=> ({type: types.UPDATE_DELETE_STAGING_EMAIL, staging_email_id}),
  updateAddSecurityUser       : (user)            => ({type: types.UPDATE_ADD_SECURITY_USER, payload: user}),
  updateOneSecurityUser       : (user)            => ({type: types.UPDATE_ONE_SECURITY_USER, payload: user}),
  updateOneStagingEmail       : (stagingEmail)    => ({type: types.UPDATE_ONE_STAGING_EMAIL, payload: stagingEmail}),
  updatePromoteStagingEmail   : (ids)             => ({type: types.UPDATE_PROMOTE_STAGING_EMAIL, payload: ids}),
  updateAddImport             : (data)            => ({type: types.UPDATE_ADD_IMPORT, payload: data}),

  requestSendEmails            : (payload)         => ({type: types.REQUEST_SEND_EMAILS, payload}),
};

 
/* ===== SELECTORS ===== */
export const selectors = {
};


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {

    case types.SET_SECURITY_INCIDENTS: {
      return { ...state, securityIncidents : action.payload };
    }
    case types.SET_SECURITY_USERS: {
      return { ...state, securityUsers : action.payload };
    }
    case types.UPDATE_ONE_SECURITY_INCIDENT: {
      let updatedIncident = action.payload;
      let updatedIncidents = state.securityIncidents.map(incident => {
        if (incident.sec_id === updatedIncident.sec_id) {
          incident.start_date = updatedIncident.start_date.toString() ?? null;
          incident.end_date = updatedIncident.end_date.toString() ?? null;
          incident.description = updatedIncident.description;
          incident.status = updatedIncident.status;
          incident.title = updatedIncident.title;
        }
        return incident;
      });
      return { ...state, securityIncidents : updatedIncidents };
    }
    case types.UPDATE_DELETE_SECURITY_INCIDENT: {
      let sec_id = action.sec_id;
      let updatedIncidents = state.securityIncidents.filter(incident => incident.sec_id !== sec_id);
      return { ...state, securityIncidents : updatedIncidents };
    }
    case types.UPDATE_ONE_COMM: {
      let updatedComm = action.payload;
      let updatedComms = state.comms.map(comm => {
        if (comm.sec_comm_id.toString() === updatedComm.sec_comm_id) {
          comm.comm_type = updatedComm.comm_type;
          comm.description = updatedComm.description;
          comm.status = updatedComm.status;
        }
        return comm;
      });
      return { ...state, comms : updatedComms };
    }
    case types.UPDATE_DELETE_COMM: {
      let sec_comm_id = action.sec_comm_id;
      let updatedComms = state.comms.filter(comm => comm.sec_comm_id !== sec_comm_id);
      return { ...state, comms : updatedComms };
    }
    case types.UPDATE_DELETE_IMPORT: {
      let imports_id = action.imports_id;
      let updatedImports = state.imports.filter(imports => imports.pkey !== imports_id);
      return { ...state, imports : updatedImports };
    }
    case types.UPDATE_ONE_COMM_CONTENT: {
      let updatedCommContent = action.payload;
      let updatedEmails = state.emails.map(commContent => {
        if (commContent.comm_content_id === updatedCommContent.comm_content_id) {
          commContent.content = updatedCommContent.content;
          commContent.content_type = updatedCommContent.content_type;
        }
        return commContent;
      });
      return { ...state, emails : updatedEmails };
    }
    case types.UPDATE_DELETE_COMM_CONTENT: {
      let comm_content_id = action.comm_content_id;
      let updatedEmails = state.emails.filter(commContent => commContent.id !== comm_content_id);
      return { ...state, emails : updatedEmails };
    }
    case types.UPDATE_DELETE_SECURITY_USER: {
      let user_id = action.user_id;
      let updatedSecurityUsers = state.securityUsers.filter(user => user.id !== user_id);
      return { ...state, securityUsers : updatedSecurityUsers };
    }
    case types.UPDATE_DELETE_STAGING_EMAIL: {
      let staging_email_id = action.staging_email_id;
      let updatedStagingEmails = state.stagingEmails.filter(stagingEmail => stagingEmail.pkey !== staging_email_id);
      return { ...state, stagingEmails : updatedStagingEmails };
    }
    case types.UPDATE_ADD_SECURITY_USER: {
      let updatedSecurityUsers = state.securityUsers
      updatedSecurityUsers.push(action.payload);
      return { ...state, securityUsers : updatedSecurityUsers };
    }
    case types.UPDATE_ADD_IMPORT: {
      let updatedImports = state.imports;
      updatedImports.push(action.payload);
      return { ...state, imports : updatedImports };
    }
    case types.UPDATE_ONE_SECURITY_USER: {  
      let updatedUser = action.payload;
      let updatedSecurityUsers = state.securityUsers.map(user => {
        if (user.id === updatedUser.id) {
          user.email = updatedUser.email;
          user.company_id = updatedUser.company_id;
        }
        return user;
      });
      return { ...state, securityUsers : updatedSecurityUsers };
    }
    case types.UPDATE_ONE_STAGING_EMAIL: {
      let updatedStagingEmail = action.payload;
      let updatedStagingEmails = state.stagingEmails.map(stagingEmail => {
        if (stagingEmail.pkey === updatedStagingEmail.pkey) {
          stagingEmail.subject = updatedStagingEmail.subject;
          stagingEmail.body = updatedStagingEmail.body;
          stagingEmail.is_valid = updatedStagingEmail.is_valid;
          stagingEmail.was_promoted = updatedStagingEmail.was_promoted;
        }
        return stagingEmail;
      });
      return { ...state, stagingEmails : updatedStagingEmails };
    }
    case types.UPDATE_PROMOTE_STAGING_EMAIL: {
      let ids = action.payload.ids;
      let updatedStagingEmails = state.stagingEmails.map(stagingEmail => {
        if (ids.includes(stagingEmail.pkey)){
          stagingEmail.was_promoted = "Y";
        }
        return stagingEmail;
      });
      return { ...state, stagingEmails : updatedStagingEmails };
    }

    case types.SET_COMM_TYPES: {
      return { ...state, commTypes : action.payload };
    }
    case types.SET_COMMS: {
      return { ...state, comms : action.payload };
    }
    case types.SET_EMAILS: {
      return { ...state, emails : action.payload };
    }
    case types.SET_IMPORTS: {
      return { ...state, imports : action.payload };
    }
    case types.SET_STAGING_EMAILS: {
      return { ...state, stagingEmails : action.payload };
    }
    case types.SET_SPECIFIC_EMAILS: {
      return { ...state, specificEmails : action.payload };
    }
    case types.SET_EMAIL_CONTENTS: {
      return { ...state, emailContents : action.payload };
    }
    case types.SET_ALL_COMM_IDS: {
      let comms = state.comms;
      if (comms && comms.length > 0){
        let allCommIds = [];
        comms.forEach(comm => {
          allCommIds.push(comm.sec_comm_id);
        });
        return { ...state, allCommIds : allCommIds };
      }
    }
    case types.SET_ALL_SEC_IDS: {
      let securityIncidents = state.securityIncidents;
      if (securityIncidents && securityIncidents.length > 0){
        let allSecIds = [];
        securityIncidents.forEach(incident => {
          allSecIds.push(incident.sec_id);
        });
        return { ...state, allSecIds : allSecIds };
      }
    }
    case types.DIRECTLY_SET_ALL_COMM_IDS: {
      return { ...state, allCommIds : action.payload };
    }
    case types.SET_SECURITY_INCIDENTS_LOADING: {
      return { ...state, securityIncidentsLoading : action.payload };
    }
    case types.SET_COMMS_LOADING: {
      return { ...state, commsLoading : action.payload };
    }
    case types.SET_COMM_TYPES_LOADING: {
      return { ...state, commTypesLoading : action.payload };
    }
    case types.SET_EMAILS_LOADING: {
      return { ...state, emailsLoading : action.payload };
    }
    case types.SET_EMAIL_PREVIEW_LOADING: {
      return { ...state, emailPreviewLoading : action.payload };
    }
    case types.SET_SPECIFIC_EMAILS_LOADING: {
      return { ...state, specificEmailsLoading : action.payload };
    }
    case types.SET_SECURITY_USERS_LOADING: {
      return { ...state, securityUsersLoading : action.payload };
    }
    case types.SET_IMPORTS_LOADING: {
      return { ...state, importsLoading : action.payload };
    }
    case types.SET_STAGING_EMAILS_LOADING: {
      return { ...state, stagingEmailsLoading : action.payload };
    }
    default: { return state; }
  }
}
