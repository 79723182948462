import React, { useState, useEffect, useCallback } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import { MdOutlineMode } from "react-icons/md";
import { Modal, Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { validateEmail, debounce } from '../SecurityIncidentHelpers';

/* ========= PACKAGE IMPORTS ========= */
const schema = yup.object().shape({
  email: yup.string().required(),
  company_id: yup.string().required(),
});

const mapStateToProps = () => ({
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    requestEditSecurityUsers              :  securityIncidentActions.requestEditSecurityUsers,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const UpdateSecurityUserModal = ({requestEditSecurityUsers, data}) => {
  const { t } = useTranslation(["translation"]);
  const [email, setEmail] = useState(data.email);
  const [companyId, setCompanyId] = useState(data.company_id);
  const [validEmail, setValidEmail] = useState(true);

  let styles = {
    buttonRight   : {
      width       : '8rem',
      margin      : '1rem'
    },
    buttonLeft: {
      width       : '8rem',
      margin      : '1rem auto 1rem 1rem',
      float       : 'left'
    },
    inputDiv: {
      marginRight: 'auto',
      height: 'unset'
    }
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const [showConfirmation,setShowConfirmation] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const submitForm = (fields) => {
    //check this again as you can submit during debounce period.
    if (!validateEmail(fields.email)) {
      return;
    }
    requestEditSecurityUsers(fields);
    setShowConfirmation(false);
    closeModal();
  }

  const debouncedEmailChecker = useCallback(
		debounce(nextValue => setValidEmail(validateEmail(nextValue)), 200),
		[],
	);

  useEffect(() => {
    if (email) {
      debouncedEmailChecker(email);
    }
  },[email]);

  return (
    <div>
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Edits this Security User</Tooltip>
        }
      >
        <MdOutlineMode onClick={openModal} style={{fontSize: "35px", color: '#007bff'}}/>
      </OverlayTrigger>
      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-update-security-user-modal">
      <Formik   
          enableReinitialize
          validationSchema={schema}
          onSubmit={values => submitForm(values)}
          initialValues={{
            email: email,
            company_id: companyId,
            id: data.id
          }}
        >
        {({
          handleSubmit,
        }) => 
        (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Security Incident</Modal.Title>
            </Modal.Header>      
            <Modal.Body>
              <div>
              <Form.Group>
                  <div>
                    <div style={styles.inputDiv}>
                      <Form.Label>Company Id</Form.Label>     
                      <Form.Control style={{height: 'unset'}} type="number" as="input" value={companyId} onChange={e => setCompanyId(e.target.value)}> 
                      </Form.Control>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <div>
                    <div style={styles.inputDiv}>
                      <Form.Label>Email</Form.Label>     
                      <Form.Control style={{height: 'unset'}} as="input" type="email" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)}> 
                      </Form.Control>
                      {!validEmail && <div style={{color: 'red'}}>Invalid Email</div>}
                    </div>
                  </div>
                </Form.Group>
              </div>
            </Modal.Body>     
            
            <Modal.Footer>
              <div style={{display: 'contents'}}>
                <Button variant="secondary" onClick={closeModal} className="data-cy-update-security-user-modal-close" style={styles.buttonLeft}>Cancel</Button>
                <Button style={styles.buttonRight} className="data-cy-update-security-user-modal-ok" type="submit">Submit</Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
        </Formik>

      </Modal>
    </div>
  );
} 

export default connect(mapStateToProps,mapDispatchToProps)(UpdateSecurityUserModal);