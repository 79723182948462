import React, {useEffect, useState}      from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdExpandMore, MdOutlineRemoveRedEye } from "react-icons/md";
import Loading            from '../../../components/loading';
import DOMPurify from 'dompurify';
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import { Modal, Button, Tooltip, OverlayTrigger, Card } from 'react-bootstrap';
import {parseHtmlFromDb} from '../SecurityIncidentHelpers';
/* ========= PACKAGE IMPORTS ========= */

const mapStateToProps = () => ({
 
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const ShowCsvData = ({data}) => {
  const [modalIsOpen,setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);

  }
  const closeModal = () => {
    setIsOpen(false);
  }


  return (
    <div style={{display: 'unset'}}>
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Show CSV Data </Tooltip>
        }
      >
        <MdOutlineRemoveRedEye style={{fontSize: '35px', color: '#007bff'}} onClick={openModal}/>
      </OverlayTrigger>
      
      <Modal size='lg' show={modalIsOpen} onHide={closeModal} className="data-cy-preview-email-modal">
        <div style={{textAlign: 'center'}}>
          <Modal.Header closeButton>
            <Modal.Title>CSV Data</Modal.Title>
          </Modal.Header>
          <Card>
            <Card.Body>
              <div>{data}</div>
            </Card.Body>
          </Card>
          <div style={{display: 'flex'}}>
            <Button variant="secondary" onClick={closeModal} className="data-cy-preview-email-modal-close" style={{margin: '1vw', width: '8rem', marginRight: 'auto'}}>Close</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(ShowCsvData);