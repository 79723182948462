import React         from "react";
import { NavLink }   from "react-router-dom";
import {
  Navbar,
  Nav,
  Button,
  NavDropdown
}                    from 'react-bootstrap';
import { shape }     from 'prop-types';
import { SVGSource, SVG } from '../../../components/locale-svg';
import NavLinkRestricted from './NavLinkRestricted';
import AuthorizationWrapper from '../../../components/authorizationWrapper'
import { userProptypes }  from '../../../ducks/user/user.index';
import sapConcurLogo      from "../../../assets/sap-concur-logo.svg";
import sapLogo            from "../../../assets/sap-logo.png";
import {actions as uiActions} from "../../../ducks/ui/ui.index"
import "./NavBar.css";
import { useTranslation } from 'react-i18next';

const YellowNavButton = ({title}) => {
  return (
    <Button variant='outline-warning'>{title}</Button>
  )
}


export default function NavBar(props) {
  const { userState, uiState, logout, setDisplayLocale } = props;
  const { t, i18n } = useTranslation(["translation"]);

  const handleClick = (lng) => {
    if (lng !== i18n.language){
      i18n.changeLanguage(lng);
      setDisplayLocale(lng);
    }
  }
  
  // let   userName              = userState.user.email;

  const styles = {
    'navBar'       :  { boxShadow: "0px 4px 19px -4px rgba(0,0,0,0.75)" },
    'sapConcurImg' :  { width: 200, maxWidth: 200, height: 36 },
    'sapImg'       :  { maxHeight: 36, maxWidth: 66, marginLeft: "1.15rem" },
    'userName'     :  { color: 'white' }
  };

  return (
    <div className="outter-nav bg-dark" style={ styles.navBar}>
      <SVGSource />
      <Navbar expand="xl" className="navbar navbar-expand-xl navbar-dark bg-dark">
        {/* brand images */}
        <Navbar.Brand className="d-flex justify-content-end">
          <img
            src   =  {sapConcurLogo}
            style =  {styles.sapConcurImg}
            alt   =  "SAP Concur Logo" />
        </Navbar.Brand>

        {/* hamburger menu, triggers < lg */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="navbar-nav mr-auto mt-2 mt-lg-0" >
            <NavLink className="nav-link" to="/" exact  >{t('containers.appNavBar.linkActive')}</NavLink>
            <NavLink className="nav-link" to="/history" >{t('containers.appNavBar.linkHistory')}</NavLink>
            <NavLink className="nav-link" to="/customers" >{t('containers.appNavBar.linkCustomers')}</NavLink>
            <NavLink className="nav-link" to="/changes">{t('containers.appNavBar.linkChange')}</NavLink>
            <NavLink className="nav-link" to="/maintenance_event/">{t('containers.appNavBar.maint')}</NavLink>
            <AuthorizationWrapper requiredAction="view_incident_management_page"><NavLink className="nav-link" to="/incident/review">{t('containers.appNavBar.linkIncident')}</NavLink></AuthorizationWrapper>
            <AuthorizationWrapper requiredAction="view_security_incidents"><NavLink className="nav-link" to="/security_incidents">Security Incidents</NavLink></AuthorizationWrapper>
            <AuthorizationWrapper requiredAction="view_dora"><NavLink className="nav-link" to="/dora/US2">Dora</NavLink></AuthorizationWrapper>
            {/* <AuthorizationWrapper requiredAction="view_charts"><NavLink className="nav-link" to="/charts">Charts</NavLink></AuthorizationWrapper> */}
            
            <AuthorizationWrapper requiredAction="view_subscriber_comm_history"><NavLink className="nav-link" to="/comm_history">Comm. History</NavLink></AuthorizationWrapper>

          </Nav>

          {/* user section */}
          <ul className="nav navbar-nav d-xl-flex align-items-xl-center">
            <AuthorizationWrapper requiredAction="view_admin_page"><li><NavLink  className="nav-link" to="/administration" ><YellowNavButton title={t('containers.appNavBar.admin')}/></NavLink></li></AuthorizationWrapper>
            <li><NavLink className="nav-link" to="/subscriptions" ><YellowNavButton title={t('containers.appNavBar.subscribe')}/></NavLink></li>
            <li><a href="https://wiki.one.int.sap/wiki/pages/viewpage.action?pageId=3221587920" rel="noopener noreferrer" className="nav-link" target="_blank"><YellowNavButton title={t('containers.appNavBar.faq')}/></a></li>  

            {/* <li className="nav-item dropdown my-2 my-lg-0 mr-sm-2 my-2 my-sm-0 ">
              { userState.isLoggedIn ? <LoggedIn userName={userName} logout={logout} /> : <LoggedOut /> }
            </li> */}
          <NavDropdown
            id="nav-locale-dropdown"
            className="ml-auto"
            title={
              <SVG
                id={i18n.language == 'en-US' ? 'en' : i18n.language}
                width='36px'
                height='36px'
              />
            }
          >
            {uiState.activeLocales.map((lng) => (
              <NavDropdown.Item key={lng} onClick={() => handleClick(lng.system_key)} >
                {lng.display_name}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
            
          </ul>
        </Navbar.Collapse>

      </Navbar>
    </div>
  );
};

NavBar.propTypes = {
  userState :  shape({ user: userProptypes.user }),
  logout    :  userProptypes.logoutUser,
};


// const LoggedIn = ({userName, logout}) => (
//   <NavDropdown title={userName} className="nav-item dropdown">
//     <NavDropdown.Item to="/settings">Settings</NavDropdown.Item>
//     <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
//   </NavDropdown>
// );
// // A "logged out" state returns no components because login is automatic (redirected to Flokta if logged out)
// const LoggedOut = () => "";
