import React, { useState, useEffect } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdDeleteForever } from "react-icons/md";
import {
  actions as securityIncidentActions
} from '../../../ducks/securityIncidents/securityIncident.index';
import {parseHtmlFromDb} from '../SecurityIncidentHelpers';
import { Modal, Button, Tooltip, OverlayTrigger, Card, Row, Col } from 'react-bootstrap';
/* ========= PACKAGE IMPORTS ========= */

const mapStateToProps = ({securityIncidentState}) => ({
  commTypes: securityIncidentState.commTypes,
  emailContents: securityIncidentState.emailContents,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    requestDeleteSecurityIncident :  securityIncidentActions.requestDeleteSecurityIncident,
    setEmailContents              : securityIncidentActions.setEmailContents,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const DeleteModalTemplate = ({deleteFunction, delete_id, data, type, emailContents, setEmailContents, keys=[], displayKeys=[], comm_type=null, html_modal=false}) => {
  const [modalIsOpen,setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }
  const submitDelete = (id) => {
    deleteFunction(id);
  }
  useEffect(() => {
    if (html_modal && data.body !== "" && modalIsOpen){
      setEmailContents(parseHtmlFromDb(null, data.body)); 
    }
  }, [data, modalIsOpen, html_modal]);
  return (
    <div>
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Deletes this {type}</Tooltip>
        }
      >
        <MdDeleteForever onClick={openModal} style={{fontSize: "35px", color: '#007bff'}}/>
      </OverlayTrigger>
      
      <Modal size='lg' show={modalIsOpen} onHide={closeModal} className="data-cy-delete-security-incident-modal">
        <Modal.Header closeButton>
          <Modal.Title>Delete {type}</Modal.Title>
        </Modal.Header>
        {!html_modal && <Card>
          <Card.Body>
            <div style={{borderBottom: '1px black solid'}}>Confirm: Are you sure you want to delete this?</div>
            <br/>
            <Row>
              <Col style={{'marginLeft': '-15px'}}>
                {displayKeys.map((key, index) => (<p key={'delete-header-' + index}>{key}:</p>))}
              </Col>
              <Col>
                {keys.map((key, index) => (key == 'comm_type' ? <p key={'delete-col-' + index}>{comm_type}</p> : <p key={'delete-col-' + index}>{data[key]}</p>))}
              </Col>
            </Row>
          </Card.Body>
        </Card>}
        {html_modal &&  <Card style={{textAlign: 'center'}}>
          <Card.Body>
            <div>Confirm: Are you sure you want to delete this?</div>
            <br/>
            <h3>{data.subject}</h3>
            <br/>
            {emailContents.map((element, index) => {
              return (
                <span style={{display: 'flex', justifyContent: 'center'}} key={index} className="Container">
                  {element}
                </span>
              )})
            }
          </Card.Body>
        </Card>}
        <div style={{display: 'flex'}}>
          <Button variant="secondary" onClick={closeModal} className="data-cy-delete-security-incident-modal-close" style={{margin: '1vw', width: '8rem', marginRight: 'auto'}}>Cancel</Button>
          <Button variant="danger" onClick={() => submitDelete(delete_id)} style={{float: 'right', width: '8rem', margin: '1vw'}}>Delete</Button>
        </div>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(DeleteModalTemplate);